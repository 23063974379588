import "./WhitelabelPageSwitcher.scss"

import classNames from "classnames"
import React from "react"

import Button from "../../atoms/Button"

const WhitelabelPageSwitcher = () => {
  const whitelabelPageSwitcherClass = classNames("whitelabel-page-switcher-wrapper")

  const goTo = page => {
    window.location.replace(page)
  }

  return (
    <div className={whitelabelPageSwitcherClass}>
      <div style={{ color: "#fff" }}>Whitelabel widget</div>
      <div className="d-flex">
        <div className="align-items-center d-flex flex-column">
          <Button margin="0 0 5px 0" onClick={() => goTo("/login")}>
            Login
          </Button>
          <Button
            margin="0 0 5px 0"
            onClick={() => goTo("/users/1/teams/1/accounts/169/selected-campaigns/6912")}
          >
            Leads page
          </Button>
        </div>
        <div className="align-items-center d-flex flex-column">
          <Button margin="0 0 5px 0" onClick={() => goTo("/users/1/teams/1/accounts/169/chat")}>
            Chat
          </Button>
          <Button margin="0 0 5px 0" onClick={() => goTo("/test")}>
            Test
          </Button>
        </div>
      </div>
    </div>
  )
}

WhitelabelPageSwitcher.propTypes = {}

WhitelabelPageSwitcher.defaultProps = {}

export default WhitelabelPageSwitcher
