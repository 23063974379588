import ACTIONS from "../../constants/ACTIONS"

const initialState = {
  data: {},
  campaignStepsStatistics: {},
  totalCountStatistic: {},
}

export default function paymentReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.GET_STATISTICS: {
      return {
        ...state,
        data: action.statistics,
      }
    }

    case ACTIONS.GET_STATISTICS_TOTAL_COUNT: {
      return {
        ...state,
        totalCountStatistic: action.total,
      }
    }

    case ACTIONS.GET_CAMPAIGN_STEPS_STATISTICS: {
      return {
        ...state,
        campaignStepsStatistics: action.statistics,
      }
    }

    case ACTIONS.USER_LOGOUT: {
      return initialState
    }

    default:
      return { ...state }
  }
}
