import "./StepperItem.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import SvgIcon from "../SvgIcon"

const StepperItem = ({ label, active, completed, icon }) => {
  const stepperItemClass = classNames("stepper-item-container", {
    "step-item-active": active,
    "step-item-completed": completed,
  })

  return (
    <div className={stepperItemClass}>
      <div className="icon-wrapper">
        <SvgIcon icon={completed ? "check-20" : icon || "file-20"} />
      </div>
      <span className="item-label large">{label}</span>
    </div>
  )
}

StepperItem.propTypes = {
  label: PropTypes.string,
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.string,
}

StepperItem.defaultProps = {
  label: "",
  active: false,
  completed: false,
  icon: undefined,
}

export default StepperItem
