import * as actions from "../redux/actions"
import { store } from "../redux/store"

const resetABTestingData = () => {
  store.dispatch(
    actions.updateFormFields({
      subjects: undefined,
      messages: undefined,
      signatures: undefined,
      signatureIds: undefined,
      useDefaultSignatures: undefined,
      hours: undefined,
      days: undefined,
      personalizedImageIds: undefined,
      personalizedImageData: undefined,
      tagInfos: undefined,
    }),
  )
}

export { resetABTestingData }
