import "./OverlayWrapper.scss"

import classNames from "classnames"
import React from "react"
import { useSelector } from "react-redux"

import Overlay from "../../layouts/Overlay"

const OverlayWrapper = () => {
  const {
    show = false,
    children,
    steps,
    title,
    footerButtonFunctions,
    activeStep,
    disabledButtons,
    tabBarItems,
    pageType,
    disableEmailSettingsForDiscoverLeadsOnly,
    isSendLeadBackToCampaign,
    footerVariant,
    overlayWrapperStyles,
  } = useSelector(state => state.app.overlayData)

  const overlayWrapperClassnames = classNames(`overlay-wrapper-container`, {
    hide: !(show || false),
  })

  return (
    <div className={overlayWrapperClassnames} style={overlayWrapperStyles}>
      <Overlay
        steps={steps}
        title={title}
        footerButtonFunctions={footerButtonFunctions}
        activeStep={activeStep}
        disabledButtons={disabledButtons}
        tabBarItems={tabBarItems}
        pageType={pageType}
        disableEmailSettingsForDiscoverLeadsOnly={disableEmailSettingsForDiscoverLeadsOnly}
        isSendLeadBackToCampaign={isSendLeadBackToCampaign}
        footerVariant={footerVariant}
      >
        {children}
      </Overlay>
    </div>
  )
}

export default OverlayWrapper
