import "./Stepper.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"
import { v4 as uuidv4 } from "uuid"

import StepperItem from "../StepperItem"

const Stepper = ({ steps, activeStep }) => {
  return (
    <div className="stepper-container">
      {steps.map((step, index) => {
        const isCompleted = index < activeStep
        const isActive = index === activeStep
        const isLastStep = index === steps.length - 1
        const stepperDividerClass = classNames("stepper-divider", {
          "stepper-divider-completed": isCompleted,
        })

        return (
          <>
            <div key={uuidv4()} className="stepper-item-wrapper">
              <StepperItem
                label={step.label}
                active={isActive}
                completed={isCompleted}
                icon={step.icon}
              />
            </div>

            {!isLastStep && <div className={stepperDividerClass} />}
          </>
        )
      })}
    </div>
  )
}

Stepper.propTypes = {
  steps: PropTypes.instanceOf(Array),
  activeStep: PropTypes.number,
}

Stepper.defaultProps = {
  steps: [],
  activeStep: 0,
}

export default Stepper
