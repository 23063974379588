import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Route, useHistory } from "react-router-dom"

import { getUserData } from "../../../redux/actions"
import LoadingPage from "../../pages/LoadingPage"

const GuestUserRoute = ({ component, redirectAuthUser, ...rest }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [loading, setLoading] = useState(true)

  const { id } = useSelector(state => state.user.profile)

  useEffect(() => {
    const initializeData = async () => {
      await dispatch(getUserData(true))
      setLoading(false)
    }
    initializeData()
  }, [])

  useEffect(() => {
    if (!!id && redirectAuthUser) {
      history.replace("/")
    }
  }, [id, redirectAuthUser])

  return loading ? <LoadingPage /> : <Route {...rest} component={component} />
}

GuestUserRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  rest: PropTypes.instanceOf(Object),
  redirectAuthUser: PropTypes.bool,
}

GuestUserRoute.defaultProps = {
  rest: {},
  redirectAuthUser: false,
}

export default GuestUserRoute
