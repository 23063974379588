import ACTIONS from "../../constants/ACTIONS"

const initialState = {
  emailAccounts: { mailboxes: [] },
  allEmailAccounts: { active: 0, mailboxes: [], total: 0 },
  isValidCustomDomain: false,
  selectedEmailAccountSettings: [],
  teamEmailCredits: {},
  emailSchedules: {
    count: 0,
    sumCount: 0,
    items: [],
  },
  emailSchedulesFetched: false,
  campaignEmailSchedule: null,
  emailScheduleCampaigns: {
    count: 0,
    items: [],
  },
  emailScheduleTemplates: {
    count: 0,
    sumCount: 0,
    items: [],
  },
  smtpImapCredentials: {},
}

export default function emailReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.GET_EMAIL_ACCOUNTS: {
      const mailboxes = action.payload.initLoad
        ? action.payload.mailboxes
        : [...state.emailAccounts.mailboxes, ...action.payload.mailboxes]
      return {
        ...state,
        emailAccounts: {
          total: action.payload.total,
          active: action.payload.active,
          mailboxes,
        },
      }
    }

    case ACTIONS.GET_ALL_EMAIL_ACCOUNTS: {
      return {
        ...state,
        allEmailAccounts: {
          active: action.payload.active || 0,
          loading: false,
          mailboxes: action.payload.mailboxes || [],
          total: action.payload.total || 0,
        },
      }
    }

    case ACTIONS.CLEAR_ALL_EMAIL_ACCOUNTS: {
      return {
        ...state,
        allEmailAccounts: {
          active: 0,
          loading: true,
          mailboxes: [],
          total: 0,
        },
      }
    }

    case ACTIONS.TEST_CUSTOM_DOMAIN: {
      return {
        ...state,
        isValidCustomDomain: action.payload.isValid,
      }
    }

    case ACTIONS.GET_EMAIL_ACCOUNT_SETTINGS: {
      return {
        ...state,
        selectedEmailAccountSettings: action.payload,
      }
    }

    case ACTIONS.RESET_EMAIL_ACCOUNT_SETTINGS: {
      return {
        ...state,
        selectedEmailAccountSettings: initialState.selectedEmailAccountSettings,
      }
    }

    case ACTIONS.GET_TEAM_EMAIL_CREDITS: {
      return {
        ...state,
        teamEmailCredits: action.payload,
      }
    }

    case ACTIONS.RESET_EMAIL_SCHEDULES_FETCHED_STATUS: {
      return {
        ...state,
        emailSchedulesFetched: false,
      }
    }

    case ACTIONS.GET_EMAIL_SCHEDULES: {
      return {
        ...state,
        emailSchedules: action.payload,
        emailSchedulesFetched: true,
      }
    }

    case ACTIONS.GET_CAMPAIGN_EMAIL_SCHEDULE: {
      return {
        ...state,
        campaignEmailSchedule: action.payload,
      }
    }

    case ACTIONS.CLEAR_CAMPAIGN_EMAIL_SCHEDULE: {
      return {
        ...state,
        campaignEmailSchedule: null,
      }
    }

    case ACTIONS.GET_EMAIL_SCHEDULE_CAMPAIGNS: {
      return {
        ...state,
        emailScheduleCampaigns: action.payload,
      }
    }

    case ACTIONS.CLEAR_EMAIL_SCHEDULE_CAMPAIGNS: {
      return {
        ...state,
        emailScheduleCampaigns: {
          count: 0,
          items: [],
        },
      }
    }

    case ACTIONS.CREATE_EMAIL_SCHEDULE: {
      const schedule = { ...action.payload }
      if (schedule.teamScheduleId) {
        delete schedule.teamScheduleId
      }
      return {
        ...state,
        emailSchedules: {
          count: state.emailSchedules.count + 1,
          sumCount: state.emailSchedules.sumCount + 1,
          items: [...state.emailSchedules.items, schedule],
        },
      }
    }

    case ACTIONS.UPDATE_EMAIL_SCHEDULE: {
      const isUpdatedSetToDefault = action.payload.changes.isDefault
      const newItems = state.emailSchedules.items.map(emailScheduleItem => {
        if (emailScheduleItem.id !== action.payload.id) {
          return isUpdatedSetToDefault
            ? { ...emailScheduleItem, isDefault: false }
            : { ...emailScheduleItem }
        }
        return {
          ...emailScheduleItem,
          ...action.payload.changes,
        }
      })
      const newEmailSchedules = {
        count: state.emailSchedules.count,
        sumCount: state.emailSchedules.sumCount,
        items: newItems,
      }
      return {
        ...state,
        emailSchedules: newEmailSchedules,
      }
    }

    case ACTIONS.DELETE_EMAIL_SCHEDULE: {
      const newEmailSchedules = {
        count: state.emailSchedules.count - 1,
        sumCount: state.emailSchedules.sumCount - 1,
        items: [
          ...state.emailSchedules.items.filter(
            emailScheduleItem => emailScheduleItem.id !== action.payload,
          ),
        ],
      }
      return {
        ...state,
        emailSchedules: newEmailSchedules,
      }
    }

    case ACTIONS.GET_EMAIL_SCHEDULE_TEMPLATES: {
      return {
        ...state,
        emailScheduleTemplates: action.payload,
      }
    }

    case ACTIONS.CREATE_EMAIL_SCHEDULE_TEMPLATE: {
      const teamSchedule = { ...action.payload }
      if (teamSchedule.teamScheduleId) {
        teamSchedule.id = teamSchedule.teamScheduleId
        delete teamSchedule.teamScheduleId
      }
      return {
        ...state,
        emailScheduleTemplates: {
          count: state.emailScheduleTemplates.count + 1,
          sumCount: state.emailScheduleTemplates.sumCount + 1,
          items: [...state.emailScheduleTemplates.items, teamSchedule],
        },
      }
    }

    case ACTIONS.DELETE_EMAIL_SCHEDULE_TEMPLATE: {
      const newEmailScheduleTemplates = {
        count: state.emailScheduleTemplates.count - 1,
        sumCount: state.emailScheduleTemplates.sumCount - 1,
        items: [
          ...state.emailScheduleTemplates.items.filter(
            emailScheduleTemplateItem => emailScheduleTemplateItem.id !== action.payload,
          ),
        ],
      }
      return {
        ...state,
        emailScheduleTemplates: newEmailScheduleTemplates,
      }
    }

    case ACTIONS.REMOVE_EMAIL_ACCOUNT: {
      const emailAccountToDelete =
        state.emailAccounts &&
        state.emailAccounts.mailboxes.find(acc => acc.mailboxId === action.payload)

      return {
        ...state,
        emailAccounts: {
          total: emailAccountToDelete ? state.emailAccounts.total - 1 : state.emailAccounts.total,
          active:
            emailAccountToDelete && emailAccountToDelete.status === "ACTIVE"
              ? state.emailAccounts.active - 1
              : state.emailAccounts.active,

          mailboxes: state.emailAccounts.mailboxes.filter(acc => acc.mailboxId !== action.payload),
        },
      }
    }

    case ACTIONS.GET_SMTP_IMAP_CREDENTIALS: {
      return {
        ...state,
        smtpImapCredentials: action.payload,
      }
    }

    default:
      return { ...state }
  }
}
