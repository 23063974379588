import axios from "axios"

import { CHAT_TYPE_FILTER_DEFAULT_OPTIONS } from "../constants/chat-constants"
import config from "../utils/config"

function getConversationsForCampaign(userID, accountID, campaignID, search) {
  switch (campaignID) {
    case CHAT_TYPE_FILTER_DEFAULT_OPTIONS.ALL.value:
      return axios.get(
        `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/conversations${search}`,
      )

    case CHAT_TYPE_FILTER_DEFAULT_OPTIONS.ARCHIVED.value:
      return axios.get(
        `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/conversations/archive${search}`,
      )

    case CHAT_TYPE_FILTER_DEFAULT_OPTIONS.CONNECTED_NOT_REPLIED.value:
      return axios.get(
        `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/conversations${search}`,
      )

    case CHAT_TYPE_FILTER_DEFAULT_OPTIONS.OTHER.value:
      return axios.get(
        `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/conversations/other${search}`,
      )

    case CHAT_TYPE_FILTER_DEFAULT_OPTIONS.UNREAD.value:
      return axios.get(
        `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/conversations/unread${search}`,
      )

    case CHAT_TYPE_FILTER_DEFAULT_OPTIONS.UNREPLIED.value:
      return axios.get(
        `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/conversations/unreplied${search}`,
      )

    default:
      return axios.get(
        ` ${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/campaigns/${campaignID}/messages${search}`,
      )
  }
}

function getMessagesForLinkedinUser(userID, accountID, linkedinUserId) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/conversations/linkedin_users/${linkedinUserId}/messages`,
  )
}

function getBelongedLeadsForThread(userID, accountID, threads) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/conversations/${threads}/belonged_leads`,
  )
}

function sendMessages(userID, accountID, thread, obj, file = []) {
  const data = new FormData()
  for (const key in obj) {
    if (obj[key]) {
      data.append(key, obj[key])
    }
  }
  if (file.length > 0) {
    for (let i = 0; i < file.length; i++) {
      const element = file[i]
      data.append(`file_upload_${i}`, element)
    }
  }

  if (obj.messageType === "EMAIL") {
    return axios.post(
      `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/conversations/${thread}/email`,
      data,
    )
  }

  return axios.post(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/conversations/${thread}/message`,
    data,
  )
}
function seenMessage(userID, accountID, thread, linkedinUserId) {
  return axios.patch(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/conversations/linkedinUsers/${linkedinUserId}/threads/${thread}/seen`,
  )
}

function fetchLinkedinMessages(userID, accountID) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/fetch_conversations`,
  )
}

function fetchEmails(userID, accountID) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/fetch_emails`,
  )
}

function saveLeadNote(userID, accountID, leadID, note) {
  return axios.post(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/leads/${leadID}/note`,
    { note },
  )
}

function getAllTags(userID, accountID) {
  return axios.get(`${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/tags`)
}

function getTagsForLeads(userID, accountID, leadIds) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/leads/tags?leadIds=[${leadIds}]`,
  )
}

function updateTagOrder(userID, accountID, tagID, tagOrder) {
  return axios.patch(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/tags/${tagID}/update_tag_order`,
    {
      tagOrder,
    },
  )
}

function createTag(userID, accountID, tagName, color) {
  return axios.post(`${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/tags`, {
    tag: tagName,
    color,
  })
}

function editTag(userID, accountID, tagId, tag, color) {
  return axios.put(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/tags/${tagId}`,
    {
      tag,
      color,
    },
  )
}

function deleteTag(userID, accountID, tagId) {
  return axios.delete(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/tags/${tagId}`,
  )
}

function addLabelToLead(userID, accountID, leadId, tagId) {
  return axios.post(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/leads/${leadId}/tags/${tagId}`,
  )
}

function removeLabelFromLead(userID, accountID, leadId, tagId) {
  return axios.delete(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/leads/${leadId}/tags/${tagId}`,
  )
}

function getLastFetchedConversation(userID, accountID) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/last_got_messages_update`,
  )
}

function markMessageAsUnread(userID, accountID, thread, linkedinUserId) {
  return axios.patch(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/conversations/linkedinUsers/${linkedinUserId}/threads/${thread}/mark_as_unread`,
  )
}

function markMessageAsUnreadByLinkedinUserIds(userID, accountID, linkedinUserIds) {
  return axios.patch(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/conversations/mark_as_unread?linkedinUserIds=[${linkedinUserIds}]`,
  )
}

function archiveMessages(userID, accountID, data) {
  return axios.patch(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/conversations/archive`,
    data,
  )
}

function deleteChatMessage(userID, accountID, thread, linkedinUserID, messageID) {
  return axios.delete(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/conversations/linkedin_users/${linkedinUserID}/${thread}/${messageID}`,
  )
}

function getLeadInfo(userID, accountID, leadID) {
  return axios.get(`
    ${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/leads/${leadID}/lead_info_for_chat
  `)
}

function deleteChatConversations(userID, accountID, data) {
  return axios.post(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/conversations/delete`,
    data,
  )
}

function getCanReplyToEmailThread(userID, accountID, thread, query) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/conversations/${thread}/can_reply${query}`,
  )
}

/** TODO MJ@Any: Don't forget to delete this code */
export default {
  archiveMessages,
  getConversationsForCampaign,
  getMessagesForLinkedinUser,
  getBelongedLeadsForThread,
  sendMessages,
  seenMessage,
  fetchLinkedinMessages,
  fetchEmails,
  saveLeadNote,
  getAllTags,
  getTagsForLeads,
  createTag,
  editTag,
  deleteTag,
  addLabelToLead,
  removeLabelFromLead,
  getLastFetchedConversation,
  markMessageAsUnread,
  markMessageAsUnreadByLinkedinUserIds,
  deleteChatMessage,
  getLeadInfo,
  deleteChatConversations,
  getCanReplyToEmailThread,
  updateTagOrder,
}
