import "./Button.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useMemo, useRef } from "react"
import { useSelector } from "react-redux"

import { useTooltip } from "../../../utils/hook"
import userBehaviourUtils from "../../../utils/userBehaviour-utils"
import Loader from "../Loader"
import SvgIcon from "../SvgIcon"

export const ButtonVariant = {
  PRIMARY: "primary",
  DANGER: "danger",
  SECONDARY: "secondary",
  DANGER_SECONDARY: "danger-secondary",
  GHOST: "ghost",
  DANGER_GHOST: "danger-ghost",
}

const Button = ({
  variant,
  type,
  onClick,
  children,
  disabled,
  icon,
  hasMenuIcon,
  margin,
  center,
  loading,
  hasLoading,
  tabIndex,
  buttonProps,
  hasMenuOpened,
  tooltipText,
  tooltipTop,
  tooltipLeft,
  customStyle,
  caretBottom,
  customClassName,
  replaceTopWithBottom,
  caretTop,
  alignItemAbove,
  blackSmallLoader,
  className,
}) => {
  const [tooltip, setTooltipLocal, closeTooltipLocal] = useTooltip(10, 0)
  const ref = useRef(null)
  const reduxLoading = useSelector(({ forms }) => forms.formData.loadingButton)
  const loadingValue = loading || (hasLoading && reduxLoading)

  const getButtonAriaLabel = useMemo(() => {
    if (typeof children === "string") {
      return children
    }
    return undefined
  }, [children])

  const onButtonClick = e => {
    if (!loadingValue) {
      onClick(e)
      userBehaviourUtils.buttonPressed(getButtonAriaLabel)
    }
  }

  const buttonClassnames = classNames(`button button__${variant}`, {
    "btn-padding-01": icon && children && hasMenuIcon,
    "btn-padding-02": icon && children && !hasMenuIcon,
    "btn-padding-03": icon && !children && !hasMenuIcon,
    "btn-padding-04": !icon && children && !hasMenuIcon,
    "m-auto d-flex": center,
  })

  const textClassnames = classNames(`button-text large`, {
    "text-min-width": children && !icon && !hasMenuIcon,
    "text-padding-01": children && !icon && !hasMenuIcon,
    "text-padding-02": children && icon && !hasMenuIcon,
    "text-padding-03": children && icon && hasMenuIcon,
  })

  const buttonContainerClassnames = classNames(`button-container`, {
    [className]: className,
  })

  const buttonStyle = { margin }

  const menuIcon = hasMenuOpened ? "chevron-up-20" : "chevron-down-20"
  if (!tooltipText) {
    return (
      <div className={buttonContainerClassnames}>
        <button
          aria-label={getButtonAriaLabel}
          onClick={onButtonClick}
          className={buttonClassnames}
          disabled={disabled || loadingValue}
          style={buttonStyle}
          type={type}
          tabIndex={tabIndex}
          ref={ref}
          {...buttonProps}
        >
          {icon && <SvgIcon icon={icon} className="button-icon" bgColor="transparent" />}
          <span className={textClassnames}>{children}</span>
          {hasMenuIcon && (
            <SvgIcon icon="chevron-down-20" className="button-icon" bgColor="transparent" />
          )}
        </button>
        {loadingValue && (
          <div className="button-small-loader">
            <Loader small blackSmallLoader={blackSmallLoader} />
          </div>
        )}
      </div>
    )
  }

  return (
    <div
      className="button-container"
      onMouseEnter={e => {
        setTooltipLocal({
          event: e,
          text: tooltipText,
          topPos: tooltipTop,
          leftPos: tooltipLeft,
          customStyle,
          caretBottom,
          customClassName,
          replaceTopWithBottom,
          caretTop,
          alignItemAbove,
        })
      }}
      onMouseLeave={() => {
        closeTooltipLocal()
      }}
    >
      {tooltip}
      <button
        aria-label={getButtonAriaLabel}
        onClick={onButtonClick}
        className={buttonClassnames}
        disabled={disabled || loadingValue}
        style={buttonStyle}
        type={type}
        tabIndex={tabIndex}
        ref={ref}
        {...buttonProps}
      >
        {icon && <SvgIcon icon={icon} className="button-icon" bgColor="transparent" />}
        <span className={textClassnames}>{children}</span>
        {hasMenuIcon && <SvgIcon icon={menuIcon} className="button-icon" bgColor="transparent" />}
      </button>
      {loadingValue && (
        <div className="button-small-loader">
          <Loader small blackSmallLoader={blackSmallLoader} />
        </div>
      )}
    </div>
  )
}

Button.propTypes = {
  variant: PropTypes.oneOf(Object.values(ButtonVariant)),
  type: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.node]).isRequired,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  hasMenuIcon: PropTypes.bool,
  margin: PropTypes.string,
  center: PropTypes.bool,
  loading: PropTypes.bool,
  hasLoading: PropTypes.bool,
  tabIndex: PropTypes.string,
  buttonProps: PropTypes.instanceOf(Object),
  hasMenuOpened: PropTypes.bool,
  tooltipText: PropTypes.string,
  tooltipTop: PropTypes.number,
  tooltipLeft: PropTypes.number,
  customStyle: PropTypes.instanceOf(Object),
  caretBottom: PropTypes.bool,
  customClassName: PropTypes.instanceOf(Object),
  replaceTopWithBottom: PropTypes.bool,
  caretTop: PropTypes.bool,
  alignItemAbove: PropTypes.bool,
  blackSmallLoader: PropTypes.bool,
  className: PropTypes.string,
}

Button.defaultProps = {
  variant: ButtonVariant.PRIMARY,
  type: "submit",
  onClick: () => {},
  disabled: false,
  icon: "",
  hasMenuIcon: false,
  margin: "",
  center: false,
  loading: false,
  hasLoading: false,
  tabIndex: "0",
  buttonProps: {},
  hasMenuOpened: false,
  tooltipText: "",
  tooltipTop: undefined,
  tooltipLeft: undefined,
  customStyle: undefined,
  customClassName: {},
  replaceTopWithBottom: undefined,
  caretBottom: undefined,
  caretTop: undefined,
  alignItemAbove: false,
  blackSmallLoader: false,
  className: "",
}

export default Button
