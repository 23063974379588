import "./CustomMenu.scss"

import { OverlayScrollbars } from "overlayscrollbars"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import Search from "../Search"

const CustomMenu = React.forwardRef(
  (
    {
      children,
      style,
      className,
      "aria-labelledby": labeledBy,
      small,
      noSearch,
      customPlaceholder,
    },
    ref,
  ) => {
    useEffect(() => {
      if (children) {
        OverlayScrollbars(document.querySelector(".list-unstyled"), {
          scrollbars: {
            autoHide: "move",
            autoHideDelay: 800,
          },
        })
      }
    }, [])

    const [value, setValue] = useState("")
    const realStyle = { ...style, ...(small ? { minWidth: 0 } : {}) }

    const handleChange = newValue => setValue(newValue)

    const findElementByClassName = (element, targetClassName) => {
      if (!element || !element.props) {
        return null
      }

      if (element.props.className === targetClassName) {
        return element
      }

      if (element.props.children && React.Children.count(element.props.children) > 0) {
        let foundElement = null
        React.Children.forEach(element.props.children, child => {
          if (!foundElement) {
            foundElement = findElementByClassName(child, targetClassName)
          }
        })
        return foundElement
      }

      return null
    }

    return (
      <div className="custom-menu-wrapper">
        <div style={realStyle} className={className} aria-labelledby={labeledBy} ref={ref}>
          {!noSearch && (
            <div className="search">
              <Search
                placeholder={customPlaceholder || "Type to filter..."}
                onChange={handleChange}
                value={value}
                onEnter={() => handleChange()}
              />
            </div>
          )}
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(child => {
              let childValue = child.props.children

              const campaignNameElement = findElementByClassName(child, "campaign-name")
              if (campaignNameElement) {
                childValue = campaignNameElement.props.children
              }

              if (Array.isArray(childValue)) {
                childValue = childValue[0]?.props?.children
              } else if (childValue && typeof childValue === "object") {
                childValue = childValue?.props?.children
              }

              return (
                !value ||
                (typeof childValue === "string" &&
                  childValue.toLowerCase().includes(value.toLowerCase().trim()))
              )
            })}
          </ul>
        </div>
      </div>
    )
  },
)

CustomMenu.propTypes = {
  children: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  style: PropTypes.instanceOf(Object),
  className: PropTypes.string,
  small: PropTypes.bool,
  noSearch: PropTypes.bool,
  customPlaceholder: PropTypes.string,
}

CustomMenu.defaultProps = {
  children: [],
  style: {},
  className: "",
  small: false,
  noSearch: false,
  customPlaceholder: "",
}

export default CustomMenu
