import "./Prompt.scss"

import classNames from "classnames"
import React, { useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"

import { closePrompt } from "../../../redux/actions"
import Footer, { FooterVariant } from "../../molecules/Footer"
import SvgIcon from "../SvgIcon"

export const PromptVariant = {
  INFO: "info",
  SUCCESS: "success",
  WARNING: "warning",
  ERROR: "error",
}

const Prompt = () => {
  const dispatch = useDispatch()
  const {
    show,
    type,
    title,
    text,
    secondaryBtnText = "Cancel",
    secondaryBtnOnClick = () => dispatch(closePrompt()),
    primaryBtnText = "Cancel",
    primaryBtnOnClick = () => dispatch(closePrompt()),
    noHideOnSubmit = false,
    primaryBtnType = undefined,
    showSecondaryBtn = true,
  } = useSelector(state => state.app.promptData)

  const promptWrapperClassnames = classNames(`prompt-wrapper`, {
    hide: !show,
  })

  const promptClassnames = classNames(`prompt-container`, {
    info: type === PromptVariant.INFO,
    success: type === PromptVariant.SUCCESS,
    warning: type === PromptVariant.WARNING,
    error: type === PromptVariant.ERROR,
  })

  const icon = useMemo(() => {
    switch (type) {
      case PromptVariant.SUCCESS:
        return "check-28"
      case PromptVariant.WARNING:
        return "alert-triangle-28"
      case PromptVariant.ERROR:
        return "circle-half-diagonal-28"
      case PromptVariant.INFO:
      default:
        return "info-circle-28"
    }
  }, [type])

  const onClickPrimaryBtn = () => {
    primaryBtnOnClick()
    if (!noHideOnSubmit) {
      dispatch(closePrompt())
    }
  }

  const footerData = useMemo(() => {
    if (type === PromptVariant.WARNING) {
      return {
        type: primaryBtnType || FooterVariant.TRANSACTIONAL_DESTRUCTIVE,
        buttons: [
          showSecondaryBtn && { label: secondaryBtnText, onClick: secondaryBtnOnClick },
          { label: primaryBtnText, onClick: onClickPrimaryBtn },
        ],
      }
    }
    return {
      type: FooterVariant.ACKNOWLEDGMENT,
      buttons: [{ label: primaryBtnText, onClick: onClickPrimaryBtn }],
    }
  }, [type])

  return (
    <div className={promptWrapperClassnames}>
      <div className={promptClassnames}>
        <div className="rectangle" />
        <div>
          <h1 className="title">{title}</h1>
          <div className="text-wrapper">
            <div className="icon">
              <SvgIcon icon={icon} />
            </div>
            <span className="text large">{text}</span>
          </div>
          <div className="footer">
            <Footer type={footerData.type} buttons={footerData.buttons} bottomRadius />
          </div>
        </div>
      </div>
    </div>
  )
}

Prompt.propTypes = {}

Prompt.defaultProps = {}

export default React.memo(Prompt)
