const CHAT_FILTER_MESSAGE_TYPE_OPTIONS = [
  {
    name: "LinkedIn",
    type: "LINKEDIN_MESSAGE",
  },
  {
    name: "Email",
    type: "EMAIL",
  },
]

const CHAT_TYPE_FILTER_DEFAULT_OPTIONS = {
  ALL: { value: "all", name: "All" },
  UNREAD: {
    value: "unread",
    name: "Unread",
    tooltip: {
      tooltipText: "The messages you haven't opened yet.",
      alignItemAbove: true,
      caretBottom: true,
      tooltipTop: 40,
    },
  },
  ARCHIVED: {
    value: "archived",
    name: "Archived",
  },
  UNREPLIED: {
    value: "unreplied",
    name: "Unreplied",
    tooltip: {
      tooltipText: "The messages you haven't replied to.",
      alignItemAbove: true,
      caretBottom: true,
      tooltipTop: 40,
    },
  },
  CONNECTED_NOT_REPLIED: {
    value: "connected_not_replied",
    name: "Waiting",
    tooltip: {
      tooltipText: "When the lead accepts your invite but hasn't replied yet.",
      alignItemAbove: true,
      caretBottom: true,
      tooltipTop: 60,
    },
  },
  OTHER: {
    value: "other",
    name: "Other",
    tooltip: {
      tooltipText: "Messages outside of your campaigns.",
      alignItemAbove: true,
      caretBottom: true,
      tooltipTop: 40,
    },
  },
}

const CHAT_TYPE_FILTER_DEFAULT_OPTIONS_VALUES_EXCEPT_ALL = Object.values(
  CHAT_TYPE_FILTER_DEFAULT_OPTIONS,
)
  .map(({ value }) => value)
  .filter(value => value !== CHAT_TYPE_FILTER_DEFAULT_OPTIONS.ALL.value)

const DEFAULT_PROFILE_IMAGE =
  "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Circle-icons-profile.svg/600px-Circle-icons-profile.svg.png"

/** If image is like this data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7 then it represents a single pixel and should be replaced */
const LINKEDIN_DEFAULT_PROFILE_IMAGE_V1 =
  "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"

const initialAllMessages = {
  messages: [],
  channels: [],
  loadingChatMessages: false,
  isFetched: false,
}

const initialSelectedConversation = {
  linkedinUser: { fullName: "" },
  labels: [],
  lead: {},
  belongedLeads: [],
  allMessages: initialAllMessages,
}

export {
  CHAT_FILTER_MESSAGE_TYPE_OPTIONS,
  CHAT_TYPE_FILTER_DEFAULT_OPTIONS,
  CHAT_TYPE_FILTER_DEFAULT_OPTIONS_VALUES_EXCEPT_ALL,
  DEFAULT_PROFILE_IMAGE,
  LINKEDIN_DEFAULT_PROFILE_IMAGE_V1,
  initialAllMessages,
  initialSelectedConversation,
}
