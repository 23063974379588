import "./ContentSwitcherItem.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import { useTooltip } from "../../../utils/hook"
import SvgIcon from "../SvgIcon"

const ContentSwitcherItem = ({ active, icon, text, disabled, onClick, tooltipData, stretched }) => {
  const [tooltip, setTooltip, closeTooltip] = useTooltip(10, 0)

  const contentSwitcherItemClass = classNames("content-switcher-item-wrapper", {
    "content-switcher-item-active": active,
    "content-switcher-item-with-icon": !!icon,
    "content-switcher-item-disbled": disabled,
    "content-switcher-item-stretched": stretched,
    "content-switcher-item-with-text": !!text,
  })

  const contentSwitcherItemIconClass = classNames("content-switcher-item-icon", {
    "content-switcher-item-icon-active": active,
  })

  return (
    <div
      className={contentSwitcherItemClass}
      onClick={() => {
        if (!disabled) {
          onClick()
        }
      }}
      onMouseEnter={e => {
        if (tooltipData.tooltipText) {
          setTooltip({
            event: e,
            text: tooltipData.tooltipText,
            topPos: tooltipData.tooltipTop,
            leftPos: tooltipData.tooltipLeft,
            caretBottom: tooltipData.caretBottom,
            alignItemAbove: tooltipData.alignItemAbove,
          })
        }
      }}
      onMouseLeave={() => {
        if (tooltipData.tooltipText) {
          closeTooltip()
        }
      }}
    >
      {tooltip}
      {icon && (
        <SvgIcon
          icon={icon}
          className={contentSwitcherItemIconClass}
          spanClassName="content-switcher-item-icon-wrapper"
        />
      )}
      {text && <span className="content-switcher-item-text">{text}</span>}
    </div>
  )
}

ContentSwitcherItem.propTypes = {
  active: PropTypes.bool,
  icon: PropTypes.string,
  text: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  tooltipData: PropTypes.shape({
    tooltipText: PropTypes.string,
    tooltipTop: PropTypes.number,
    tooltipLeft: PropTypes.number,
    caretBottom: PropTypes.number,
    alignItemAbove: PropTypes.bool,
  }),
  stretched: PropTypes.bool,
}

ContentSwitcherItem.defaultProps = {
  active: false,
  icon: "",
  text: "",
  disabled: false,
  onClick: () => {},
  tooltipData: {},
  stretched: false,
}

export default ContentSwitcherItem
