import "./AllIconsPage.scss"

import React from "react"

import SvgIcon from "../../atoms/SvgIcon"
import mainIcons from "../../atoms/SvgIcon/mainIcons"

const AllIconsPage = () => {
  return (
    <div className="all-icon-table">
      <h1>All Icons</h1>

      <h2>Main icons</h2>
      <table>
        <thead>
          <th>Icon</th>
          <th>Name</th>
        </thead>
        <tbody>
          {Object.keys(mainIcons).map(icon => (
            <tr>
              <td>
                <SvgIcon key={icon} icon={icon} style={{ width: 30, height: 30, margin: 10 }} />
              </td>

              <td>{icon}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default AllIconsPage
