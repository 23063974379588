import mixpanel from "mixpanel-browser"

/**
 * Set event tracking
 *
 * @returns {Promise}
 */
function track(event, payload = {}) {
  if (process.env.NODE_ENV === "production") {
    return mixpanel.track(event, payload)
  }
}

/**
 * Init user data
 */
async function identifyProfile(distinctId, userData = {}) {
  if (process.env.NODE_ENV === "production") {
    mixpanel.identify(distinctId)
    mixpanel.people.set(userData)
  }
}

export default {
  track,
  identifyProfile,
}
