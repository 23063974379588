import PropTypes from "prop-types"
import React, { useMemo, useRef } from "react"

import { useTooltip } from "../../../utils/hook"
import { getIcon } from "./icons-utils"

const SvgIcon = ({
  className,
  style,
  icon,
  onClick,
  spanClassName,
  testid,
  tooltipText,
  tooltipTop,
  tooltipLeft,
  customStyle,
  customClassName,
  replaceTopWithBottom,
  showTooltip,
  timeoutTime,
  setTooltipToParent,
  closeTooltipToParent,
  caretBottom,
  caretTop,
  alignItemAbove,
}) => {
  const [tooltip, setTooltipLocal, closeTooltipLocal] = useTooltip(10, 0)

  const setTooltip = setTooltipToParent || setTooltipLocal
  const closeTooltip = closeTooltipToParent || closeTooltipLocal

  const timeoutRef = useRef(null)

  const svgIcon = useMemo(() => {
    return icon ? getIcon(className, style, icon) : icon
  }, [icon, className])

  if (!tooltipText) {
    return (
      <span style={{ display: "flex" }} className={spanClassName} onClick={onClick}>
        {svgIcon}
      </span>
    )
  }

  return (
    <div
      data-testid={testid}
      onMouseEnter={e => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current)
        }
        setTooltip({
          event: e,
          text: tooltipText,
          topPos: tooltipTop,
          leftPos: tooltipLeft,
          customStyle,
          caretBottom,
          customClassName,
          replaceTopWithBottom,
          caretTop,
          alignItemAbove,
        })
      }}
      onMouseLeave={() => {
        if (!timeoutTime) {
          closeTooltip()
        } else {
          timeoutRef.current = setTimeout(closeTooltip, timeoutTime)
        }
      }}
    >
      {showTooltip && tooltip}
      <span style={{ display: "flex" }} className={spanClassName} onClick={onClick}>
        {svgIcon}
      </span>
    </div>
  )
}

SvgIcon.propTypes = {
  className: PropTypes.string,
  style: PropTypes.instanceOf(Object),
  icon: PropTypes.string,
  onClick: PropTypes.func,
  spanClassName: PropTypes.string,
  testid: PropTypes.string,
  tooltipText: PropTypes.string,
  tooltipTop: PropTypes.number,
  tooltipLeft: PropTypes.number,
  customStyle: PropTypes.instanceOf(Object),
  customClassName: PropTypes.instanceOf(Object),
  replaceTopWithBottom: PropTypes.bool,
  showTooltip: PropTypes.func,
  timeoutTime: PropTypes.number,
  setTooltipToParent: PropTypes.func,
  closeTooltipToParent: PropTypes.func,
  caretBottom: PropTypes.bool,
  caretTop: PropTypes.bool,
  alignItemAbove: PropTypes.bool,
}

// eslint-disable-next-line no-irregular-whitespace
/** The default values ​​must remain undefined, otherwise we are passing invalid values ​​to setTooltip() */
SvgIcon.defaultProps = {
  className: "",
  style: {},
  icon: undefined,
  onClick: undefined,
  spanClassName: "",
  testid: undefined,
  tooltipText: "",
  tooltipTop: undefined,
  tooltipLeft: undefined,
  customStyle: undefined,
  customClassName: {},
  replaceTopWithBottom: undefined,
  showTooltip: () => {},
  timeoutTime: undefined,
  setTooltipToParent: undefined,
  closeTooltipToParent: undefined,
  caretBottom: undefined,
  caretTop: undefined,
  alignItemAbove: false,
}

export default React.memo(SvgIcon)
