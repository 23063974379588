const DEFAULT_ROLES = ["Owner", "Admin", "Editor"]

const TEAMS_PAGE_TABS = {
  SEATS: {
    key: "SEATS",
    title: "Seats",
  },
  TEAM_MEMBERS: {
    key: "TEAM_MEMBERS",
    title: "Team members",
  },
  ROLES_AND_PERMISSIONS: {
    key: "ROLES_AND_PERMISSIONS",
    title: "Roles & permissions",
  },
  TEAM_BLACKLIST: {
    key: "TEAM_BLACKLIST",
    title: "Team blacklist",
  },
}

export { DEFAULT_ROLES, TEAMS_PAGE_TABS }
