import ACTIONS from "../../constants/ACTIONS"

const initialState = {
  profile: { availableSeats: [] },
  userProfile: {},
  userCount: 0,
  allUsers: [],
  activeUser: {},
  savedReplies: [],
  featureRequestsObject: {
    count: 0,
    featureRequests: [],
  },
  featureRequest: { comments: [] },
  seat: {},
  linkedinUserData: {},
  openApiKey: "",
  twoFactor: {},
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.USER_REGISTER:
    case ACTIONS.USER_LOGIN:
      return {
        ...state,
        profile: { ...state.profile, ...action.userData },
      }
    case ACTIONS.GET_AVAILABLE_SEATS_FOR_USER:
      return {
        ...state,
        profile: { ...state.profile, availableSeats: action.availableSeats },
      }
    case ACTIONS.GET_USER_DATA:
      return {
        ...state,
        userProfile: action.userData,
      }
    case ACTIONS.CLEAR_ALL_USERS:
      return {
        ...state,
        allUsers: [],
        userCount: 0,
      }
    case ACTIONS.GET_ALL_USERS:
      return {
        ...state,
        allUsers: action.allUsers,
        userCount: action.userCount,
      }

    case ACTIONS.GET_ACTIVE_USER:
      return {
        ...state,
        activeUser: action.activeUser,
      }

    case ACTIONS.GET_ALL_SAVED_REPLIES: {
      return {
        ...state,
        savedReplies: action.savedReplies,
      }
    }

    case ACTIONS.GET_ALL_FEATURE_REQUESTS: {
      return {
        ...state,
        featureRequestsObject: {
          count: action.featureRequests.count,
          featureRequests: [
            ...(action.init ? [] : state.featureRequestsObject.featureRequests),
            ...action.featureRequests.featureRequests,
          ],
        },
      }
    }

    case ACTIONS.GET_FEATURE_REQUESTS_BY_ID: {
      return {
        ...state,
        featureRequest: action.featureRequest,
      }
    }

    case ACTIONS.GET_SEAT: {
      return {
        ...state,
        seat: action.seat,
      }
    }

    case ACTIONS.GET_LINKEDIN_USER: {
      return {
        ...state,
        linkedinUserData: action.linkedinUserData,
      }
    }

    case ACTIONS.CLEAR_LINKEDIN_USER: {
      return {
        ...state,
        linkedinUserData: initialState.linkedinUserData,
      }
    }

    case ACTIONS.GET_OPEN_API_KEY: {
      return {
        ...state,
        openApiKey: action.openApiKey,
      }
    }

    case ACTIONS.USER_LOGOUT:
      return initialState

    case ACTIONS.VALIDATE_TWO_FACTOR: {
      return {
        ...state,
        twoFactor: { ...state.twoFactor, isVerified: action.payload },
      }
    }

    case ACTIONS.GENERATE_TWO_FACTOR_SECRET_URI: {
      return {
        ...state,
        twoFactor: {
          ...state.twoFactor,
          otpUri: action.payload.otpUri,
          secret: action.payload.secret,
        },
      }
    }

    default:
      return { ...state }
  }
}
