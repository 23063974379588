import "./TabBar.scss"

import PropTypes from "prop-types"
import React from "react"

import Tab from "../Tab"

const TabBar = ({ items }) => {
  return (
    <div className="tab-bar-wrapper">
      {items.map(item => {
        if (item.canView) {
          return (
            <div
              onMouseEnter={e => item.setTooltip && item.setTooltip(e)}
              onMouseLeave={() => item.closeTooltip && item.closeTooltip()}
            >
              <Tab
                label={item.label}
                isActive={item.isActive}
                onClick={item.onClick}
                disabled={item.disabled || false}
              />
            </div>
          )
        }
        return null
      })}
    </div>
  )
}

TabBar.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      canView: PropTypes.bool,
      label: PropTypes.string,
      isActive: PropTypes.bool,
      onClick: PropTypes.func,
    }),
  ).isRequired,
}

export default TabBar
