import mainIcons from "./mainIcons"

const getIcon = (className, style, icon) => {
  let svgIcon

  const svgIconFunc = mainIcons[icon]
  if (svgIconFunc) {
    svgIcon = svgIconFunc(className, style)
  }

  return svgIcon
}

export { getIcon }
