import PropTypes from "prop-types"

const EMAIL_ACCOUNT_STATUS = {
  ACTIVE: "Connected",
  INACTIVE: "Disconnected",
  COOLDOWN: "On cooldown",
}

const EMAIL_SCHEDULER_MIN_TIME_RANGE = 1800

const EMAIL_SCHEDULER_TEAM_TEMPLATES_LIMIT = 30

const EMAIL_SCHEDULER_TABS = {
  MY_SCHEDULES: {
    key: "MY_SCHEDULES",
    title: "My Schedules",
    description: "Manage your schedules.",
  },
  TEAM_SCHEDULES: {
    key: "TEAM_SCHEDULES",
    title: "Team schedules",
    description: "Manage your team's schedules.",
  },
}

const EMAIL_SCHEDULER_MODALS = {
  CREATE: "CREATE",
  EDIT: "EDIT",
  USE: "USE",
}

const EMAIL_SCHEDULER_WEEKDAYS = [
  { key: "Monday", initialCharName: "M", day: 1 },
  { key: "Tuesday", initialCharName: "T", day: 2 },
  { key: "Wednesday", initialCharName: "W", day: 3 },
  { key: "Thursday", initialCharName: "T", day: 4 },
  { key: "Friday", initialCharName: "F", day: 5 },
  { key: "Saturday", initialCharName: "S", day: 6 },
  { key: "Sunday", initialCharName: "S", day: 7 },
]

const EMAIL_SCHEDULE_DAY_PROP_TYPE = {
  day: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  checked: PropTypes.bool.isRequired,
}

const EMAIL_SCHEDULE_PROP_TYPE = {
  intervals: PropTypes.arrayOf(
    PropTypes.shape({
      day: PropTypes.number,
      startTime: PropTypes.string,
      endTIme: PropTypes.string,
    }),
  ).isRequired,
  id: PropTypes.number.isRequired,
  isDefault: PropTypes.bool.isRequired,
  isUsed: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
    fullName: PropTypes.string,
  }).isRequired,
  createdAt: PropTypes.string,
  lastChangeAt: PropTypes.string,
}

const CAN_REPLY_TO_EMAIL_THREAD_STATUS = {
  REPLY_NOT_AVAILABLE: "REPLY_NOT_AVAILABLE",
  REPLY_AVAILABLE: "REPLY_AVAILABLE",
  REPLY_AVAILABLE_VIA_DIFFERENT_MAILBOX: "REPLY_AVAILABLE_VIA_DIFFERENT_MAILBOX",
}

const SIGNATURE_EDITOR = {
  RICH_TEXT: 1,
  HTML: 2,
}

export {
  CAN_REPLY_TO_EMAIL_THREAD_STATUS,
  EMAIL_ACCOUNT_STATUS,
  EMAIL_SCHEDULER_MIN_TIME_RANGE,
  EMAIL_SCHEDULER_MODALS,
  EMAIL_SCHEDULER_TABS,
  EMAIL_SCHEDULER_TEAM_TEMPLATES_LIMIT,
  EMAIL_SCHEDULER_WEEKDAYS,
  EMAIL_SCHEDULE_DAY_PROP_TYPE,
  EMAIL_SCHEDULE_PROP_TYPE,
  SIGNATURE_EDITOR,
}
