import moment from "moment-timezone"
import { toast } from "react-toastify"

import ACTIONS from "../../constants/ACTIONS"
import API from "../../constants/API"
import { defaultAutoReuseInterval } from "../../constants/campaign-constants"
import settings from "../../constants/settings"
import campaignService from "../../services/campaign-service"
import {
  getCreateCampaignRequestData,
  getRealDashboard,
  objectToFormData,
  parseCampaignSteps,
  prepareForCampaignSubmit,
} from "../../utils/campaign-utils"
import dataUtils from "../../utils/data-utils"
import { mapLeadForSelectCampaign } from "../../utils/lead-utils"
import { apiError, startApiCall } from "../api/apiActions"
import { clearForm, updateFormField, updateFormFields } from "../forms/formsActions"
import { history } from "../store"

function getAllCampaignSuccess(allCampaigns) {
  return { type: ACTIONS.GET_ALL_CAMPAIGNS, allCampaigns }
}

function getAllCampaigns(query = "") {
  return async (dispatch, getState) => {
    try {
      dispatch(startApiCall(API.GET_ALL_CAMPAIGNS))
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      const allCampaigns = await campaignService.getAllCampaigns(userID, accountID, query)
      dispatch(getAllCampaignSuccess(allCampaigns.data.result))
      return allCampaigns.data.result
    } catch (error) {
      dispatch(apiError(API.GET_ALL_CAMPAIGNS, error))
    }
  }
}

function clearAllCampaignNames() {
  return { type: ACTIONS.CLEAR_ALL_CAMPAIGN_NAMES }
}

function getAllActiveCampaignsSuccess(allActiveCampaigns) {
  return { type: ACTIONS.GET_ALL_ACTIVE_CAMPAIGNS, allActiveCampaigns }
}

function getAllActiveCampaigns() {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      const allActiveCampaigns = await campaignService.getAllActiveCampaigns(userID, accountID)
      dispatch(getAllActiveCampaignsSuccess(allActiveCampaigns.data.result.items))
    } catch (error) {}
  }
}

function importFile(campaignID, file, dashboard, removeDuplicates) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      await campaignService.importFile(
        userID,
        accountID,
        campaignID,
        dashboard,
        file,
        removeDuplicates,
      )
      return true
    } catch (error) {
      return false
    }
  }
}

function reuseLeadList(leadSources) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      await campaignService.reuseLeadList(userID, accountID, leadSources)

      toast.success("Refresh started successfully.")
      return true
    } catch (error) {
      return false
    }
  }
}

function scheduleAutoReuse(leadSources) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      await campaignService.scheduleAutoReuse(userID, accountID, leadSources)
      return true
    } catch (error) {
      return false
    }
  }
}

function validateLeadSources(leadSource) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      await campaignService.validateLeadSources(userID, accountID, leadSource)

      return true
    } catch (error) {
      return false
    }
  }
}

function createLeadSource(leadSourceData, showToast = true) {
  return async (dispatch, getState) => {
    try {
      const { campaignId, dashboard, campaignType } = leadSourceData
      const { formData } = getState().forms
      const createLeadSourceData = [
        {
          campaignId,
          leadSourceUrl: formData.campaignUrl,
          dashboard: Number(getRealDashboard(dashboard) || formData.realDashboard),
          leadSourceType: campaignType || formData.campaignType,
          autoReuse: typeof formData.autoReuse === "undefined" ? false : formData.autoReuse,
          autoReuseInterval:
            typeof formData.autoReuseInterval === "undefined"
              ? defaultAutoReuseInterval
              : formData.autoReuseInterval,
        },
      ]
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      await campaignService.createLeadSource(userID, accountID, createLeadSourceData)

      if (showToast) {
        toast.success("New leads successfully added.")
      }

      return true
    } catch (error) {}
  }
}

function selectDraftCampaign(draftCampaignId) {
  return { type: ACTIONS.SELECT_DRAFT_CAMPAIGN, draftCampaignId }
}

function createDraftCampaign() {
  return async (dispatch, getState) => {
    try {
      const data = getCreateCampaignRequestData({ stateId: 2 })

      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      const campaign = await campaignService.createCampaign(userID, accountID, data)

      const { formData } = getState().forms
      let success = true
      if (formData.dashboard && formData.dashboard !== "import") {
        success = await dispatch(createLeadSource({ campaignId: campaign.data.result.id }, false))
      }

      await dispatch(selectDraftCampaign(campaign.data.result.id))
      return success
    } catch (error) {
      return false
    }
  }
}

function updateDraftCampaignSuccess(draftCampaignUpdateCounter) {
  return { type: ACTIONS.UPDATE_DRAFT_CAMPAIGN, draftCampaignUpdateCounter }
}

function updateDraftCampaign(additionalData = { stateId: 2 }) {
  return async (dispatch, getState) => {
    try {
      const { createCampaignLoading } = getState().forms.formData
      if (!createCampaignLoading) {
        const userID = getState().user.profile.id
        const accountID = getState().account.activeAccountID
        const { draftCampaignId, draftCampaignUpdateCounter } = getState().campaign
        const { formData } = getState().forms

        const newData = prepareForCampaignSubmit(additionalData, formData.campaignStepsType)

        await campaignService.updateDraftCampaign(userID, accountID, draftCampaignId, newData)

        const { dashboard, autoReuse, autoReuseInterval } = getState().forms.formData

        let success = true
        if (dashboard && dashboard !== "import") {
          success = await dispatch(
            createLeadSource({ campaignId: draftCampaignId, autoReuse, autoReuseInterval }, false),
          )
        }
        await dispatch(updateDraftCampaignSuccess(draftCampaignUpdateCounter + 1))
        return success
      }
    } catch (error) {
      return false
    }
  }
}

function clearCsvInfo() {
  return { type: ACTIONS.CLEAR_CSV_INFO }
}

function createCampaign() {
  return async (dispatch, getState) => {
    try {
      dispatch(updateFormField("createCampaignLoading", true))
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      const { draftCampaignId } = getState().campaign
      const { formData } = getState().forms
      const { removeDuplicates } = formData
      const additionalData = { stateId: 1 }

      const newData = prepareForCampaignSubmit(additionalData, formData.campaignStepsType, true)

      const newFormData = objectToFormData({ ...newData, removeDuplicates })
      await campaignService.updateDraftCampaignAndLeadSources(
        userID,
        accountID,
        draftCampaignId,
        newFormData,
      )

      const teamID = getState().teamManagement.selectedTeam.teamId
      history.replace(`/users/${userID}/teams/${teamID}/accounts/${accountID}`)
      dispatch(clearForm())
      dispatch(clearCsvInfo())
      return true
    } catch (error) {
      return false
    } finally {
      await dispatch(updateFormField("createCampaignLoading", false))
    }
  }
}

function getLeadsByCampaignSuccess(campaignLeads) {
  return { type: ACTIONS.GET_LEADS_BY_CAMPAIGN, campaignLeads }
}

function changeLeadsByCampaign(campaignLeads) {
  return dispatch => {
    dispatch(getLeadsByCampaignSuccess(campaignLeads))
  }
}

function getLeadsByCampaign(campaignID, sort = "?limit=20") {
  return async (dispatch, getState) => {
    try {
      if (campaignID) {
        const userID = getState().user.profile.id
        const accountID = getState().account.activeAccountID
        let campaignLeads
        if (campaignID !== "all") {
          campaignLeads = await campaignService.getLeadsByCampaign(
            userID,
            accountID,
            campaignID,
            sort,
          )
        } else {
          campaignLeads = await campaignService.getLeadsByAllCampaigns(userID, accountID, sort)
        }
        await dispatch(getLeadsByCampaignSuccess(campaignLeads.data.result))
      } else {
        await dispatch(getLeadsByCampaignSuccess({ count: 0, items: [], campaignStats: {} }))
      }
    } catch (error) {}
  }
}

function changeLeadVariables(leadId, editedData, campaignID, query) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      const { active, ...data } = editedData

      const leadsUpdated = await campaignService.changeLeadVariables(
        userID,
        accountID,
        leadId,
        data,
      )
      if (leadsUpdated) {
        await dispatch(getLeadsByCampaign(campaignID, query))
      }
      toast.success("Lead changes successfully saved")
    } catch (error) {}
  }
}

function deleteCampaign(campaignID) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      const { allCampaigns } = getState().campaign
      await campaignService.deleteCampaign(userID, accountID, campaignID)
      dispatch(
        getAllCampaignSuccess({
          campaigns: allCampaigns.campaigns.filter(campaign => +campaign.id !== campaignID),
          count: allCampaigns.count - 1,
        }),
      )
    } catch (error) {}
  }
}

function changeLeadActivitySuccess(allLeads) {
  return { type: ACTIONS.CHANGE_LEAD_ACTIVITY, allLeads }
}

function blockLead(leadID) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      const allLeads = getState().campaign.campaignLeads.items
      await campaignService.blockLead(userID, accountID, leadID)
      const blockedLeadIndex = allLeads.findIndex(lead => +lead.id === +leadID)
      allLeads[blockedLeadIndex].active = false
      dispatch(changeLeadActivitySuccess(allLeads))
    } catch (error) {}
  }
}

function blockLeads(leadIDS) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      const allLeads = getState().campaign.campaignLeads.items
      await campaignService.blockLeads(userID, accountID, leadIDS)
      const leadsWithNewStatus = allLeads.map(lead => {
        if (leadIDS.includes(lead.id)) {
          lead.active = false
        }
        return lead
      })
      dispatch(changeLeadActivitySuccess(leadsWithNewStatus))
    } catch (error) {}
  }
}

function unblockLead(leadID) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      const allLeads = getState().campaign.campaignLeads.items
      await campaignService.unblockLead(userID, accountID, leadID)
      const unblockedLeadIndex = allLeads.findIndex(lead => +lead.id === +leadID)
      allLeads[unblockedLeadIndex].active = true
      dispatch(changeLeadActivitySuccess(allLeads))
    } catch (error) {}
  }
}

function unblockLeads(leadIDS) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      const allLeads = getState().campaign.campaignLeads.items
      await campaignService.unblockLeads(userID, accountID, leadIDS)
      const leadsWithNewStatus = allLeads.map(lead => {
        if (leadIDS.includes(lead.id)) {
          lead.active = true
        }
        return lead
      })
      dispatch(changeLeadActivitySuccess(leadsWithNewStatus))
    } catch (error) {}
  }
}

function deleteLeadsSuccess(allLeads) {
  return { type: ACTIONS.DELETE_LEADS, allLeads }
}

function deleteLeads(leadIDS, campaignID, query) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      const allLeads = getState().campaign.campaignLeads.items
      await campaignService.deleteLeads(userID, accountID, leadIDS)
      const newLeadsWithoutDeletedLeads = allLeads.filter(lead => !leadIDS.includes(lead.id))
      dispatch(deleteLeadsSuccess(newLeadsWithoutDeletedLeads))
      dispatch(getLeadsByCampaign(campaignID, query))
    } catch (error) {}
  }
}

function deleteCampaignsSuccess(allCampaigns) {
  return { type: ACTIONS.DELETE_CAMPAIGNS, allCampaigns }
}

function deleteCampaigns(campaignIds) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      const allCampaigns = getState().campaign.allCampaigns.campaigns

      await campaignService.deleteCampaigns(userID, accountID, campaignIds)
      const filteredCampaigns = allCampaigns.filter(camp => !campaignIds.includes(camp.id))
      dispatch(deleteCampaignsSuccess(filteredCampaigns))
    } catch (error) {}
  }
}

function activateCampaign(campaignID) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      await campaignService.activateCampaign(userID, accountID, campaignID)
      return true
    } catch (error) {
      return false
    }
  }
}

function deactivateCampaign(campaignID) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      await campaignService.deactivateCampaign(userID, accountID, campaignID)
      return true
    } catch (error) {
      return false
    }
  }
}

function changeCampaignState(campaignID, campaignState) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      await campaignService.changeCampaignState(userID, accountID, campaignID, campaignState)
      dispatch(getAllCampaigns())
      return true
    } catch (error) {
      return false
    }
  }
}

function clearAdditionalVariables() {
  return { type: ACTIONS.CLEAR_ADDITIONAL_VARIABLES }
}

function setAdditionalVariablesSuccess(additionalVariables) {
  return { type: ACTIONS.SET_ADDITIONAL_VARIABLES, additionalVariables }
}

function setAdditionalVariables(additionalVariables) {
  return async dispatch => {
    try {
      const allUniqueTagsFormatted = dataUtils.formatVariableTags(additionalVariables, {})
      dispatch(setAdditionalVariablesSuccess(allUniqueTagsFormatted))
      return true
    } catch (error) {
      return false
    }
  }
}

function setCsvInfo(csvInfo) {
  return { type: ACTIONS.GET_CSV_INFO, csvInfo }
}

function importValidation(file) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      const additionalVariables = await campaignService.importValidation(file, userID, accountID)
      dispatch(setCsvInfo(additionalVariables.data.result))
      dispatch(setAdditionalVariables(additionalVariables.data.result.items))
      return additionalVariables.data.result
    } catch (error) {
      return false
    }
  }
}

function getCampaignDetailsSuccess(campaignDetails) {
  return { type: ACTIONS.GET_CAMPAIGN_DETAILS, campaignDetails }
}

function getCampaignDetails(campaignID) {
  return async (dispatch, getState) => {
    try {
      if (campaignID === "all") {
        const mockCampaign = { campaignSteps: [] }
        dispatch(getCampaignDetailsSuccess(mockCampaign))
        return mockCampaign
      }

      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      const campaignDetails = await campaignService.getCampaignDetails(
        userID,
        accountID,
        campaignID,
      )
      dispatch(getCampaignDetailsSuccess(campaignDetails.data.result))
      dispatch(setAdditionalVariables(campaignDetails.data.result.additionalData || []))
      return campaignDetails.data.result
    } catch (error) {
      return false
    }
  }
}

function clearCampaignDetails() {
  return { type: ACTIONS.CLEAR_CAMPAIGN_DETAILS }
}

function getAllCampaignStepsSuccess(campaignSteps) {
  return { type: ACTIONS.GET_CAMPAIGN_STEPS, campaignSteps }
}

function getAllCampaignSteps(campaignID) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      const campaignSteps = await campaignService.getAllCampaignSteps(userID, accountID, campaignID)
      dispatch(getAllCampaignStepsSuccess(campaignSteps.data.result.items))
    } catch (error) {}
  }
}

function getAllCampaignNamesSuccess(allCampaignNames) {
  return { type: ACTIONS.GET_CAMPAIGN_NAMES, allCampaignNames }
}

function getAllCampaignNames(campaignID) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      const allCampaignNames = await campaignService.getAllCampaignNames(
        userID,
        accountID,
        campaignID,
      )
      dispatch(getAllCampaignNamesSuccess(allCampaignNames.data.result.items))
      return true
    } catch (error) {
      return false
    }
  }
}

function editCampaign(campaignID, selectedLeadSources) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      const { formData } = getState().forms
      const { leadSources } = getState().campaign.campaignDetails

      let campaignSteps = formData?.campaignStepsEdit?.map(currentStep => {
        if (currentStep.action === "email") {
          const doc = new DOMParser().parseFromString(currentStep.data.message, "text/html")
          const rawEmail = doc.getElementsByClassName("ql-editor")
          if (!rawEmail.length) {
            let { signatureId, useDefaultSignature } = currentStep.data
            if (useDefaultSignature === null) {
              useDefaultSignature = false
            }
            if (useDefaultSignature && signatureId) {
              signatureId = ""
            }
            return {
              ...currentStep,
              data: {
                ...currentStep.data,
                signatureId,
                useDefaultSignature,
                message: dataUtils.createHTMLEmailTemplate(
                  currentStep.data.message,
                  currentStep.data.subject,
                ),
              },
            }
          }
        }
        return currentStep
      })
      const changedData = {}

      campaignSteps = parseCampaignSteps(campaignSteps)

      if (campaignSteps && campaignSteps.length > 0) {
        changedData.editCampaignSteps = campaignSteps
      }

      if (formData.campaignName) {
        changedData.name = formData.campaignName
      }

      if (formData.scheduleId) {
        changedData.scheduleId = formData.scheduleId
      }

      const leadSourceTypesSet = new Set(
        (leadSources || []).map(leadSource => leadSource.leadSourceType),
      )
      changedData.leadSourceTypes = [...leadSourceTypesSet]

      if (formData.campaignStepsTree) {
        let stepsWithoutEdges = formData.campaignStepsTree.filter(
          step => !step.id.includes("reactflow__edge"),
        )
        stepsWithoutEdges = parseCampaignSteps(stepsWithoutEdges, true)

        changedData.stepsTree = stepsWithoutEdges.concat(
          formData.campaignStepsTree.filter(step => step.id.includes("reactflow__edge")),
        )
        changedData.editCampaignSteps = campaignSteps.map(newData => {
          return {
            ...newData,
            id: getState().campaign.campaignDetails.campaignSteps.find(
              step => step.step === newData.step,
            )?.id,
            /**
             * When StepsTree are present, all ids are i.e. "dndnode_xx",
             * we need to get it back as it came from details endpoint
             */
          }
        })
      }
      changedData.editCampaignSteps =
        changedData.editCampaignSteps?.map(step => ({
          ...step,
          replyToStep: step.replyToStep ? step.replyToStep : null,
          /**
           * Changes all undefined-s to nulls, this is necessary for backend
           * to know to reset its replyToStep value in database
           */
        })) || []

      await campaignService.editCampaign(userID, accountID, campaignID, changedData)

      if (selectedLeadSources?.length) {
        await dispatch(scheduleAutoReuse(selectedLeadSources, false))
      }
      if (formData.campaignName) {
        dispatch(getAllCampaignNames(campaignID))
      }
      toast.success("Campaign changes successfully saved")
      return true
    } catch (error) {
      // eslint-disable-next-line
      console.error("Error with updating campaign", error)
      return false
    }
  }
}

function getExportFile(campaignID) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      await campaignService.getExportFile(userID, accountID, campaignID)
    } catch (error) {}
  }
}

function addLeadBackToCampaign(leadID, nextStepId, campaignId, currentCampaignId) {
  return async (dispatch, getState) => {
    try {
      const { formData } = getState().forms
      const newStartUTCTime = moment.tz(
        `${formData.startDate.format("DD MM YYY")} ${formData.startUTCTime}`,
        "DD MM YYY HH:mm",
        moment.tz.guess(),
      )

      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      const doTaskAt = newStartUTCTime.valueOf()
      dispatch(clearForm())

      await campaignService.addLeadBackToCampaign(userID, accountID, leadID, {
        campaignId,
        step: nextStepId,
        doTaskAt,
      })

      const leadsFromSelectCampaign = getState().campaign.campaignLeads.items

      const { campaignDetails } = getState().campaign

      const { action: nextStep, step: currentStep } = campaignDetails.campaignSteps.find(
        step => +step.id === nextStepId,
      )

      const newLeads = leadsFromSelectCampaign
        .map(lead => {
          if (lead.id === leadID) {
            return mapLeadForSelectCampaign(lead, nextStep, nextStepId, currentStep)
          }
          return lead
        })
        .filter(lead => {
          if (+lead.id === +leadID) {
            return +campaignId === +currentCampaignId
          }
          return true
        })

      dispatch(changeLeadActivitySuccess(newLeads))

      toast.success("Lead successfully returned into the campaign")
      return true
    } catch (error) {
      return false
    }
  }
}

function setSelectedNodeHandler(selectedNode) {
  return { type: ACTIONS.SET_SELECTED_NODE, selectedNode }
}

function setSelectedTabSuccess(selectedTab) {
  return { type: ACTIONS.SET_SELECTED_TAB, selectedTab }
}

function setSelectedTabHandler(selectedTab) {
  return dispatch => {
    dispatch(setSelectedTabSuccess(selectedTab))
  }
}

function getGlobalSavedSequencesSuccess(savedSequences, init) {
  return { type: ACTIONS.GET_SAVED_SEQUENCES, savedSequences, init }
}

function getMyTemplateSuccess(teamTemplate, init) {
  return { type: ACTIONS.GET_TEAM_TEMPLATE, teamTemplate, init }
}

function getStepTemplatesSuccess(stepTemplates, init) {
  return { type: ACTIONS.GET_STEP_TEMPLATES, stepTemplates, init }
}

function replaceMyTemplate(teamTemplate) {
  return { type: ACTIONS.REPLACE_TEAM_TEMPLATE, teamTemplate }
}

function replaceStepTemplate(stepTemplates) {
  return { type: ACTIONS.REPLACE_STEP_TEMPLATES, stepTemplates }
}

function getMySavedSequences(query = "?search", init) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const teamID = getState().teamManagement.selectedTeam.teamId
      const teamTemplateCount = getState().campaign.teamTemplate.count
      const savedSequences = await campaignService.getMySavedSequences(userID, teamID, query)
      const { count, items } = savedSequences.data.result

      const finalItems = []
      let finalCount = init ? count : teamTemplateCount

      items.forEach(sequence => {
        if (!sequence.isGlobalTemplate) {
          finalItems.push(sequence)
        } else {
          finalCount--
        }
      })

      const sequenceData = {
        items: finalItems,
        count: finalCount,
      }
      await dispatch(getMyTemplateSuccess(sequenceData, init))
      return true
    } catch (error) {
      return false
    }
  }
}

function getStepTemplates(query = "?search", init) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const steps = await campaignService.getStepTemplates(userID, query)
      const { items, count } = steps.data.result

      await dispatch(getStepTemplatesSuccess({ items, count }, init))
      return true
    } catch (error) {
      return false
    }
  }
}

function saveStepTemplate() {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const teamID = getState().teamManagement.selectedTeam.teamId
      const accountID = getState().account.activeAccountID

      const { stepTemplate } = getState().forms.formData

      const step = stepTemplate.length ? stepTemplate[0] : {}
      const steps = parseCampaignSteps([step], true)

      const { templateName: title, templateDescription } = getState().forms.formData
      const data = { title, steps }
      if (templateDescription !== "") {
        data.description = templateDescription
      }

      await campaignService.saveStepTemplate(userID, teamID, accountID, data)

      const query = `?limit=${settings.templatesLimit}&offset=${0}`
      await dispatch(getStepTemplates(query, true))
      await dispatch(
        updateFormFields({
          templateName: undefined,
          templateDescription: undefined,
        }),
      )
      toast.success("Successfully saved template")
      return true
    } catch (error) {
      toast.error("Template saving failed. Please try again.")
      return false
    }
  }
}

function deleteStepTemplate(stepTemplateId) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      await campaignService.deleteStepTemplate(userID, stepTemplateId)

      return true
    } catch (error) {
      return false
    }
  }
}

function getGlobalSavedSequences(query = "?search", init) {
  return async (dispatch, getState) => {
    try {
      const { whitelabelId, id: userID } = getState().user.profile
      const { formData } = getState().forms

      const queryWithLeadSourceType = `${query}&leadSourceType=${formData.campaignType}`

      const savedSequences = await campaignService.getGlobalSavedSequences(
        userID,
        whitelabelId,
        queryWithLeadSourceType,
      )
      const { count, items } = savedSequences.data.result
      const sequenceData = {
        items: [],
        count,
      }
      items.forEach(sequence => {
        if (sequence.isGlobalTemplate) {
          sequenceData.items.push(sequence)
        }
      })
      await dispatch(getGlobalSavedSequencesSuccess(sequenceData, init))
      return true
    } catch (error) {
      return false
    }
  }
}

function exportAllCampaignData() {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      const { formData } = getState().forms

      const data = { recipientEmail: formData["export-email"] }
      await campaignService.exportAllCampaignData(userID, accountID, data)
      toast.success("You scheduled the export successfully.")
    } catch (error) {}
  }
}

function createMyTemplate() {
  return async (dispatch, getState) => {
    try {
      const { campaignStepsType } = getState().forms.formData
      const { formData } = getState().forms

      const newSteps = prepareForCampaignSubmit({}, campaignStepsType).campaignSteps
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID

      const data = {
        title: formData.templateName,
        isGlobalTemplate: false,
        userId: userID,
        steps: newSteps,
      }

      if (formData.templateDescription !== "") {
        data.description = formData.templateDescription
      }

      await campaignService.createMyTemplate(userID, accountID, data)

      await dispatch(
        updateFormFields({
          templateName: undefined,
          templateDescription: undefined,
        }),
      )
      toast.success("Successfully saved template")
      return true
    } catch (error) {
      return false
    }
  }
}

function deleteMyTemplate(sequenceId) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      await campaignService.deleteMyTemplate(userID, sequenceId)
      return true
    } catch (error) {
      return false
    }
  }
}

function setCampaignMailboxes(mailboxIds, campaignId) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID

      const data = {
        mailboxIds,
      }

      await campaignService.setCampaignMailboxes(userID, accountID, campaignId, data)
      return true
    } catch (error) {
      return false
    }
  }
}

function getCampaignGlobalStatisticsSuccess(statistic) {
  return { type: ACTIONS.GET_GLOBAL_CAMPAIGN_STATISTIC, statistic }
}

function getCampaignGlobalStatistics() {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID

      const response = await campaignService.getCampaignGlobalStatistics(userID, accountID)
      dispatch(getCampaignGlobalStatisticsSuccess(response.data.result))
      return true
    } catch (error) {
      return false
    }
  }
}

function resetCampaignGlobalStatistics() {
  return { type: ACTIONS.RESET_GLOBAL_CAMPAIGN_STATISTIC }
}

export {
  getAllCampaigns,
  getAllActiveCampaigns,
  selectDraftCampaign,
  createDraftCampaign,
  updateDraftCampaign,
  clearCsvInfo,
  createCampaign,
  createLeadSource,
  getLeadsByCampaign,
  changeLeadVariables,
  deleteCampaign,
  blockLead,
  blockLeads,
  unblockLead,
  unblockLeads,
  deleteLeads,
  activateCampaign,
  deactivateCampaign,
  importValidation,
  clearAdditionalVariables,
  setAdditionalVariables,
  getExportFile,
  importFile,
  getCampaignDetails,
  clearCampaignDetails,
  getAllCampaignSteps,
  editCampaign,
  getAllCampaignNames,
  addLeadBackToCampaign,
  clearAllCampaignNames,
  changeLeadsByCampaign,
  changeCampaignState,
  reuseLeadList,
  validateLeadSources,
  setSelectedNodeHandler,
  setSelectedTabHandler,
  getMySavedSequences,
  getStepTemplates,
  saveStepTemplate,
  deleteStepTemplate,
  exportAllCampaignData,
  createMyTemplate,
  deleteMyTemplate,
  getGlobalSavedSequences,
  setCampaignMailboxes,
  deleteCampaigns,
  replaceMyTemplate,
  replaceStepTemplate,
  getCampaignGlobalStatistics,
  resetCampaignGlobalStatistics,
}
