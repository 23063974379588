import "./EmailIntegrationBody.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { Col } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"

import { updateFormField } from "../../../redux/actions"
import InputGroup from "../../organisms/InputGroup"
import EditEmailModalSectionWrapper from "../EditEmailModalSectionWrapper"
import Loader from "../Loader"
import RadioButtonGroup from "../RadioButtonGroup"
import SvgIcon from "../SvgIcon"

const EmailIntegrationBody = ({ integrationType }) => {
  const dispatch = useDispatch()

  const { loadingSmtp, loadingImap, smtpTest, imapTest } = useSelector(
    state => state.forms.formData,
  )

  const [multiplePasswordType, setMultiplePasswordType] = useState({
    "smtp-password": "password",
    "imap-password": "password",
  })

  const senderNameClassName = classNames("input-on-surface", {
    "disabled-sender-email": integrationType === "reconnect",
  })

  const enableSSL = value => {
    dispatch(updateFormField("smtpSsl", value))
  }

  const renderActionMessages = (success, type) => {
    return (
      <div className="integration-message">
        <SvgIcon
          icon={success ? "check-16" : "circle-half-diagonal-16"}
          className={
            success ? "integration-message__success-icon" : "integration-message__error-icon"
          }
        />
        <span
          className={
            success ? "integration-message__success-text" : "integration-message__error-text"
          }
        >
          {success ? `${type} has connected successfully` : `${type} has failed`}
        </span>
      </div>
    )
  }

  return (
    /** TODO PP@Any: Add link here (Visit help center) */
    <div className="integration-body-wrapper">
      {/* <div>link</div> */}
      <EditEmailModalSectionWrapper
        title="Sender info"
        description="Type your email and define your sender's name - the display name your leads will see."
      >
        <div className="integration-body-section-right">
          <InputGroup
            field="smtp-sender-email"
            label="Sender email"
            placeholder="example@domain.com"
            inputGroupClassName={senderNameClassName}
            disabled={integrationType === "reconnect"}
          />
          <InputGroup
            field="smtp-sender-name"
            label="Sender name"
            placeholder="First and last name"
            inputGroupClassName="input-on-surface"
          />
        </div>
      </EditEmailModalSectionWrapper>
      <div>
        {loadingSmtp ? (
          <Col className="loading">
            <Loader fullWidth />
          </Col>
        ) : (
          <EditEmailModalSectionWrapper
            title="SMTP settings"
            description="To add a custom SMTP domain, head to your email or hosting provider's settings and grab the info you need. Then, fill in the fields to connect your domain."
          >
            <div className="integration-body-section-right">
              <InputGroup
                field="smtp-username"
                label="User name"
                placeholder="example@domain.com"
                inputGroupClassName="input-on-surface"
              />
              <InputGroup
                field="smtp-password"
                label="Password"
                inputGroupClassName="input-on-surface"
                type={multiplePasswordType["smtp-password"]}
                multiplePasswordType={multiplePasswordType}
                setMultiplePasswordType={setMultiplePasswordType}
              />
              <InputGroup
                field="smtp-ip"
                label="Hostname"
                placeholder="e.g. smtp.domain.com or xx.xxx.xxx.xx"
                inputGroupClassName="input-on-surface"
              />
              <InputGroup
                field="smtp-port"
                label="Port number"
                placeholder="e.g. 456"
                inputGroupClassName="input-on-surface"
              />
            </div>
          </EditEmailModalSectionWrapper>
        )}
        {loadingImap ? (
          <Col className="loading">
            <Loader fullWidth />
          </Col>
        ) : (
          <EditEmailModalSectionWrapper
            title="IMAP settings"
            description="To add custom IMAP domain, head to your email or hosting provider's settings and grab the info you need. Then, fill in the fields to connect your domain."
          >
            <div className="integration-body-section-right">
              <InputGroup
                field="imap-username"
                label="User name"
                placeholder="example@domain.com"
                inputGroupClassName="input-on-surface"
              />
              <InputGroup
                field="imap-password"
                label="Password"
                inputGroupClassName="input-on-surface"
                type={multiplePasswordType["imap-password"]}
                multiplePasswordType={multiplePasswordType}
                setMultiplePasswordType={setMultiplePasswordType}
              />
              <InputGroup
                field="imap-ip"
                label="Hostname"
                placeholder="e.g. imap.domain.com or xx.xxx.xxx.xx"
                inputGroupClassName="input-on-surface"
              />
              <InputGroup
                field="imap-port"
                label="Port number"
                placeholder="e.g. 993"
                inputGroupClassName="input-on-surface"
              />
            </div>
          </EditEmailModalSectionWrapper>
        )}

        <EditEmailModalSectionWrapper
          title="Security"
          description="Choose if you want to use TLS/SSL encryption to further secure your data."
          withoutBottomMargin
        >
          <div className="integration-body-section-right">
            <RadioButtonGroup
              name="smtpSsl"
              onChange={async () => {
                enableSSL(true)
              }}
              label="TLS/SSL"
            />
            <RadioButtonGroup
              name="smtpSsl"
              defaultChecked
              onChange={() => {
                enableSSL(false)
              }}
              label="Don't use encryption"
            />
          </div>
        </EditEmailModalSectionWrapper>
      </div>
      <div className="smtp-errors-class mt-4">
        {smtpTest != null && renderActionMessages(smtpTest, "SMTP")}
        {imapTest != null && renderActionMessages(imapTest, "IMAP")}
      </div>
    </div>
  )
}

EmailIntegrationBody.propTypes = {
  integrationType: PropTypes.string.isRequired,
}

EmailIntegrationBody.defaultProps = {}

export default EmailIntegrationBody
