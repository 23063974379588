import { connectRouter } from "connected-react-router"
import { combineReducers } from "redux"

import accountReducer from "./account/accountReducer"
import appReducer from "./app/appReducer"
import blacklistReducer from "./blacklist/blacklistReducer"
import campaignReducer from "./campaign/campaignReducer"
import chatReducer from "./chat/chatReducer"
import emailReducer from "./emails/emailReducer"
import formsReducer from "./forms/formsReducer"
import imagePersonalizationReducer from "./imagePersonalization/imagePersonalizationReducer"
import paymentReducer from "./payment/paymentReducer"
import statisticsReducer from "./statistics/statisticsReducer"
import teamManagementReducer from "./team-management/teamManagementReducer"
import userReducer from "./user/userReducer"

export default history =>
  combineReducers({
    router: connectRouter(history),
    app: appReducer,
    user: userReducer,
    account: accountReducer,
    forms: formsReducer,
    payment: paymentReducer,
    campaign: campaignReducer,
    chat: chatReducer,
    imagePersonalization: imagePersonalizationReducer,
    statistics: statisticsReducer,
    teamManagement: teamManagementReducer,
    blacklist: blacklistReducer,
    email: emailReducer,
  })
