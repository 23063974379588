import "./DarkThemeSwitch.scss"

import React, { useMemo, useState } from "react"
import { useDispatch } from "react-redux"

import { updateFormField } from "../../../redux/actions"
import { useConfig } from "../../../utils/hook"
import ContentSwitcher from "../ContentSwitcher"

const DarkThemeSwitch = () => {
  const dispatch = useDispatch()
  const config = useConfig()
  const themeFromLocalStorage = localStorage.getItem("theme")

  const [theme, setTheme] = useState(themeFromLocalStorage)

  const isDarkThemeActive = theme === "dark-theme"
  const isLightThemeActive = theme !== "dark-theme"

  const contentSwitcheritems = useMemo(() => {
    return [
      { status: 1, icon: "sun-20", active: isLightThemeActive },
      { status: 2, icon: "moon-20", active: isDarkThemeActive },
    ]
  }, [theme])

  const setDarkTheme = () => {
    document.body.classList.remove(["light-theme"])
    document.body.classList.add("changing-theme-fade")
    document.body.classList.add("dark-theme")
    localStorage.setItem("theme", "dark-theme")
    dispatch(updateFormField("logo", config.REACT_APP_LOGO_IMAGE_DARK_THEME))

    setTheme("dark-theme")
    setTimeout(() => {
      document.body.classList.remove("changing-theme-fade")
    }, 1000)
  }

  const setLightTheme = () => {
    document.body.classList.remove(["dark-theme"])
    document.body.classList.add("changing-theme-fade")
    document.body.classList.add("light-theme")
    localStorage.setItem("theme", "light-theme")
    dispatch(updateFormField("logo", config.REACT_APP_LOGO_IMAGE_LIGHT_THEME))

    setTheme("light-theme")
    setTimeout(() => {
      document.body.classList.remove("changing-theme-fade")
    }, 1000)
  }

  const changeTheme = ({ status }) => {
    if (status === 1 && isDarkThemeActive) {
      setLightTheme()
    }
    if (status === 2 && isLightThemeActive) {
      setDarkTheme()
    }
  }

  return (
    <div className="theme-switch-container">
      <div>
        <div className="large">Theme</div>
        <div className="small-description">{isLightThemeActive ? "Light" : "Dark"}</div>
      </div>
      <ContentSwitcher items={contentSwitcheritems} onClick={changeTheme} />
    </div>
  )
}

DarkThemeSwitch.propTypes = {}

DarkThemeSwitch.defaultProps = {}

export default DarkThemeSwitch
