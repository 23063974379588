import "./CardWrapper.scss"

import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import PropTypes from "prop-types"
import React from "react"

import config from "../../../utils/config"

const stripePromise = loadStripe(config.REACT_APP_STRIPE_API_KEY)

const CardWrapper = ({ children }) => {
  return <Elements stripe={stripePromise}>{children}</Elements>
}

CardWrapper.propTypes = {
  children: PropTypes.instanceOf(Array).isRequired,
}

CardWrapper.defaultProps = {}

export default React.memo(CardWrapper)
