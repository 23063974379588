import "./RadioButtonGroup.scss"

import PropTypes from "prop-types"
import React from "react"

import RadioButton from "../RadioButton"

const RadioButtonGroup = ({ checked, defaultChecked, disabled, label, name, onChange }) => {
  return (
    <div className="radio-btn-group">
      <RadioButton
        checked={checked}
        defaultChecked={defaultChecked}
        disabled={disabled}
        name={name}
        onChange={onChange}
      />
      <span className="label-text large">{label}</span>
    </div>
  )
}

RadioButtonGroup.propTypes = {
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string,
  onChange: PropTypes.func,
}

RadioButtonGroup.defaultProps = {
  checked: undefined,
  defaultChecked: undefined,
  disabled: false,
  label: "",
  name: "",
  onChange: () => {},
}

export default RadioButtonGroup
