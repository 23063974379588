/* eslint-disable import/no-cycle */
import { toast } from "react-toastify"

import { PromptVariant } from "../../components/atoms/Prompt"
import ACTIONS from "../../constants/ACTIONS"
import accountService from "../../services/account-service"
import { getEmailAccounts } from "../actions"
import { showPrompt } from "../app/appActions"
import { store } from "../store"
import { getTeamAccounts } from "../team-management/teamManagementActions"

function getSettingsDataSuccess(settingsData) {
  return { type: ACTIONS.GET_SETTING_DATA, settingsData }
}

function getSettingsData() {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID

      const settingsData = await accountService.getSettingsData(userID, accountID)
      dispatch(getSettingsDataSuccess(settingsData.data.result))
      return settingsData.data.result
    } catch (error) {}
  }
}

function setSettingsData(data) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      await accountService.setSettingsData(userID, accountID, data)
      dispatch(getSettingsData())
      toast.success("Successfully saved")
      return true
    } catch (error) {
      return false
    }
  }
}

function setMaxPendingConnectionsSuccess(maxPendingAmount) {
  return { type: ACTIONS.SET_ACCOUNT_HEALTH, maxPendingAmount }
}

function setMaxPendingConnections(maxPendingAmount) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      await accountService.setMaxPendingConnections(userID, accountID, { maxPendingAmount })
      dispatch(setMaxPendingConnectionsSuccess(maxPendingAmount))
      toast.success("Successfully saved")
    } catch (error) {}
  }
}

function setTheme(theme) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID

      localStorage.setItem("selectedTheme", theme)
      await accountService.setTheme(userID, accountID, theme)
    } catch (error) {}
  }
}

function getPendingConnectionsSuccess(pendingConnections) {
  return { type: ACTIONS.GET_PENDING_CONNECTIONS, pendingConnections }
}

function getPendingConnections() {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      const pendingConnections = await accountService.getPendingConnections(userID, accountID)
      dispatch(getPendingConnectionsSuccess(pendingConnections.data.result.shouldRemove))
      return pendingConnections.data.result.shouldRemove
    } catch (error) {}
  }
}

function removePendingConnections(shouldRemove) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      await accountService.removePendingConnections(userID, accountID, shouldRemove)
    } catch (error) {}
  }
}

function fetchLinkedinState() {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID

      await accountService.fetchLinkedinState(userID, accountID)
      toast.info("Your request is sent")
    } catch (error) {
      if (error.response?.data?.error?.message && error.response.data.error.code === 409) {
        dispatch(showPrompt(PromptVariant.ERROR, "Warning", error.response.data.error.message))
      }
    }
  }
}

function disconnectEmail(
  emailType,
  userID = store.getState().user.profile.id,
  accountID = store.getState().account.activeAccountID,
  shouldRefreshAccounts,
) {
  return async dispatch => {
    try {
      await accountService.disconnectEmail(userID, accountID, emailType)
      dispatch(getEmailAccounts())
      if (shouldRefreshAccounts) {
        dispatch(getTeamAccounts())
      }
    } catch (error) {}
  }
}

function getRunOnWeekendsSuccess(weekendOptions) {
  return { type: ACTIONS.GET_RUN_ON_WEEKENDS, weekendOptions }
}

function getRunOnWeekends() {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID

      const response = await accountService.getRunOnWeekends(userID, accountID)
      dispatch(getRunOnWeekendsSuccess(response.data.result))
    } catch (error) {}
  }
}

function setRunOnWeekends(runOnWeekends) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      await accountService.setRunOnWeekends(userID, accountID, {
        runOnWeekends,
      })
      dispatch(getRunOnWeekends())
      toast.success("Successfully saved")
    } catch (error) {}
  }
}

function setWeekendDays(weekendDaysId) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      const data = {
        weekendDaysId,
      }
      await accountService.setWeekendDays(userID, accountID, data)
      dispatch(getRunOnWeekends())
      return true
    } catch (error) {
      return false
    }
  }
}

export {
  disconnectEmail,
  fetchLinkedinState,
  getPendingConnections,
  getRunOnWeekends,
  getSettingsData,
  removePendingConnections,
  setMaxPendingConnections,
  setRunOnWeekends,
  setSettingsData,
  setTheme,
  setWeekendDays,
}
