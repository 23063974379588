import { PromptVariant } from "../../components/atoms/Prompt"
import ACTIONS from "../../constants/ACTIONS"
import imagePersonalizationService from "../../services/image-personalization-service"
import dataUtils from "../../utils/data-utils"
import { deleteImageTemplateFromCampaign } from "../../utils/image-personalization-utils"
import { showPrompt } from "../actions"

function getCloudinaryPermissions() {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      const data = await imagePersonalizationService.getCloudinaryPermissions(userID, accountID)
      return data.data.result
    } catch (error) {}
  }
}

function uploadCloudinary(file) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      const data = await imagePersonalizationService.uploadCloudinary(userID, accountID, file)
      return data.data
    } catch (error) {
      return {
        error:
          error.response && error.response.status && error.response.status === 413
            ? "Image is too large"
            : "Something went wrong",
      }
    }
  }
}

function getMyTemplatesSuccess(myTemplates) {
  return { type: ACTIONS.GET_MY_TEMPLATES, myTemplates }
}

function getMyTemplates() {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      const myTemplates = await imagePersonalizationService.getMyTemplates(userID, accountID)
      dispatch(getMyTemplatesSuccess(myTemplates.data.result.items))
    } catch (error) {}
  }
}

function getDefaultTemplatesSuccess(defaultTemplates) {
  return { type: ACTIONS.GET_DEFAULT_TEMPLATES, defaultTemplates }
}

function getDefaultTemplates() {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      const defaultTemplates = await imagePersonalizationService.getDefaultTemplates(
        userID,
        accountID,
      )
      dispatch(getDefaultTemplatesSuccess(defaultTemplates.data.result.items))
    } catch (error) {}
  }
}

function createPersonalizedImage(publicId, transformations) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      const propsToRound = ["x", "y", "width", "height", "rotation"]
      const data = {
        publicId,
        transformations: transformations.map(
          ({ data: newData, type, fontSize, fontFamily, textAlign }) => {
            const coordinates = dataUtils.getCloudinaryCoordinates({
              x: newData.x,
              y: newData.y,
              width: newData.width,
              height: newData.height,
              angle: newData.rotation,
            })
            return {
              data: Object.keys(newData).reduce((obj, key) => {
                if (key === "x") {
                  obj[key] = Math.ceil(coordinates.x)
                } else if (key === "y") {
                  obj[key] = Math.ceil(coordinates.y)
                } else if (propsToRound.includes(key)) {
                  obj[key] = Math.ceil(newData[key])
                } else if (key === "fontSize") {
                  obj[key] = Math.ceil(newData[key])
                } else {
                  obj[key] = newData[key]
                }
                return obj
              }, {}),
              meta: {
                type,
                fontSize: Math.ceil(fontSize),
                fontFamily,
                textAlign,
              },
            }
          },
        ),
      }
      const imageResp = await imagePersonalizationService.createPersonalizedImage(
        userID,
        accountID,
        data,
      )
      return imageResp.data
    } catch (error) {}
  }
}

function getPersonalizedImageDataSuccess(imageId, imageData) {
  return { type: ACTIONS.GET_PERSONALIZED_IMAGE_DATA, imageId, imageData }
}

function getPersonalizedImageData(personalizedImageId) {
  return async (dispatch, getState) => {
    try {
      const fetchedImages = getState().imagePersonalization.images
      if (fetchedImages.some(img => img.id === personalizedImageId)) {
        return
      }

      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      const personalizedImage = await imagePersonalizationService.getPersonalizedImageData(
        userID,
        accountID,
        personalizedImageId,
      )
      dispatch(getPersonalizedImageDataSuccess(personalizedImageId, personalizedImage.data))
    } catch (error) {}
  }
}

function uploadImageToBucket(file) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      const personalizedImage = await imagePersonalizationService.uploadImageToBucket(
        userID,
        accountID,
        file,
      )
      return { type: file.type, url: personalizedImage.data.result.value }
    } catch (error) {
      return {
        error:
          error.response && error.response.status && error.response.status === 413
            ? "Image is too large"
            : "Something went wrong",
      }
    }
  }
}

function deleteImageTemplate(imageId, allElements, changeAllElements) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      dispatch(
        showPrompt(
          PromptVariant.WARNING,
          "warning",
          "Deleting this template affects all campaigns in which used. Messages will be sent without the template. Are you sure you want to delete it?",
          async () => {
            await imagePersonalizationService.deleteImageTemplate(userID, accountID, imageId)
            deleteImageTemplateFromCampaign(imageId, allElements, changeAllElements)
            dispatch(getMyTemplates())
          },
          "Delete template",
        ),
      )
    } catch (error) {}
  }
}

export {
  createPersonalizedImage,
  deleteImageTemplate,
  getCloudinaryPermissions,
  getDefaultTemplates,
  getMyTemplates,
  getPersonalizedImageData,
  uploadCloudinary,
  uploadImageToBucket,
}
