import axios from "axios"

import config from "../utils/config"

function getStatistics(userID, accountID, query) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/statistics${query}`,
  )
}

function getCampaignStepsStatistics(userID, accountID, campaignId) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/statistics/steps?campaignId=${campaignId}`,
  )
}

export default {
  getStatistics,
  getCampaignStepsStatistics,
}
