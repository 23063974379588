import axios from "axios"

import config from "../utils/config"

function getWhitelabelPermissions(whitelabelId) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/whitelabel_permission/${whitelabelId}/get_all_whitelabel_permissions`,
  )
}

export default {
  getWhitelabelPermissions,
}
