import "./Footer.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import Button, { ButtonVariant } from "../../atoms/Button"

export const FooterVariant = {
  TRANSACTIONAL: "transactional",
  TRANSACTIONAL_DESTRUCTIVE: "transactional-destructive",
  ACKNOWLEDGMENT: "acknowledgment",
  PROGRESS: "progress",
  PROGRESS_LARGE: "progress-large",
}
const Footer = ({ buttons, type, bottomRadius, noPadding, customClass }) => {
  const footerClassName = classNames("footer-wrapper", {
    "progress-large": type === "progress-large",
    "bottom-radius": bottomRadius,
    "no-padding": noPadding,
    [customClass]: customClass,
  })

  const renderFooter = () => {
    switch (type) {
      case FooterVariant.TRANSACTIONAL:
      case FooterVariant.TRANSACTIONAL_DESTRUCTIVE:
        return (
          <div className={footerClassName}>
            <Button
              variant={ButtonVariant.GHOST}
              onClick={buttons[0]?.onClick}
              disabled={buttons[0]?.disabled}
            >
              {buttons[0].label}
            </Button>
            {buttons[1] && (
              <Button
                variant={
                  type === FooterVariant.TRANSACTIONAL
                    ? ButtonVariant.PRIMARY
                    : ButtonVariant.DANGER
                }
                onClick={buttons[1]?.onClick}
                disabled={buttons[1]?.disabled}
                hasLoading
              >
                {buttons[1]?.label}
              </Button>
            )}
          </div>
        )
      case FooterVariant.ACKNOWLEDGMENT:
        return (
          <div className={footerClassName}>
            <Button
              variant={ButtonVariant.GHOST}
              onClick={buttons[0]?.onClick}
              disabled={buttons[0]?.disabled}
            >
              {buttons[0]?.label}
            </Button>
          </div>
        )
      case FooterVariant.PROGRESS:
      case FooterVariant.PROGRESS_LARGE:
        return (
          <div className={footerClassName}>
            <div className="footer-actions">
              {buttons[0] && (
                <Button
                  variant={buttons[0]?.variant || ButtonVariant.GHOST}
                  onClick={buttons[0]?.onClick}
                  disabled={buttons[0]?.disabled}
                >
                  {buttons[0]?.label}
                </Button>
              )}

              <div className="footer-right">
                {buttons[1] && (
                  <Button
                    variant={buttons[1]?.variant || ButtonVariant.GHOST}
                    onClick={buttons[1]?.onClick}
                    disabled={buttons[1]?.disabled}
                  >
                    {buttons[1]?.label}
                  </Button>
                )}
                {buttons[2] && (
                  <Button
                    variant={buttons[2]?.variant || ButtonVariant.PRIMARY}
                    onClick={buttons[2]?.onClick}
                    disabled={buttons[2]?.disabled}
                  >
                    {buttons[2]?.label}
                  </Button>
                )}
                {buttons[3] && (
                  <Button
                    variant={buttons[3]?.variant || ButtonVariant.GHOST}
                    onClick={buttons[3]?.onClick}
                    disabled={buttons[3]?.disabled}
                  >
                    {buttons[3]?.label}
                  </Button>
                )}
                {buttons[4] && (
                  <Button
                    variant={buttons[4]?.variant || ButtonVariant.PRIMARY}
                    onClick={buttons[4]?.onClick}
                    disabled={buttons[4]?.disabled}
                    hasLoading
                  >
                    {buttons[4]?.label}
                  </Button>
                )}
              </div>
            </div>
          </div>
        )
    }
  }

  return renderFooter()
}

Footer.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ).isRequired,
  type: PropTypes.oneOf(Object.values(FooterVariant)),
  bottomRadius: PropTypes.bool,
  customClass: PropTypes.string,
}

Footer.defaultProps = {
  type: FooterVariant.TRANSACTIONAL,
  bottomRadius: false,
  customClass: "",
}

export default Footer
