import "./WhitelabelWidget.scss"

import classNames from "classnames"
import React from "react"

import { useDraggable } from "../../../utils/hook"
import DarkThemeSwitch from "../../atoms/DarkThemeSwitch"
import WhitelabelPageSwitcher from "../WhitelabelPageSwitcher"
import WhitelabelThemeSwitcher from "../WhitelabelThemeSwitcher"

const WhitelabelWidget = () => {
  const whitelabelWidgetClass = classNames("whitelabel-widget-wrapper")

  const [ref] = useDraggable()

  return (
    <div ref={ref} className={whitelabelWidgetClass}>
      <WhitelabelThemeSwitcher />
      <WhitelabelPageSwitcher />
      <DarkThemeSwitch />
    </div>
  )
}

WhitelabelWidget.propTypes = {}

WhitelabelWidget.defaultProps = {}

export default WhitelabelWidget
