import axios from "axios"

import config from "../utils/config"

function addToBlacklist(userID, accountID, data) {
  return axios.patch(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/blacklists/add_keyword`,
    data,
  )
}

function addToGlobalBlacklist(teamID, userID, data) {
  return axios.patch(
    `${config.REACT_APP_BACKEND_URL}/teams/${teamID}/users/${userID}/global_blacklists/add_keyword`,
    data,
  )
}

function deleteBlacklist(userID, accountID, data) {
  return axios.post(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/blacklists/delete`,
    data,
  )
}

function deleteGlobalBlacklist(teamID, userID, data) {
  return axios.post(
    `${config.REACT_APP_BACKEND_URL}/teams/${teamID}/users/${userID}/global_blacklists/delete`,
    data,
  )
}

function getEmailBlacklist(userID, accountID, query) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/blacklists/email${query}`,
  )
}

function getGlobalEmailBlacklist(teamId, userID, query) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/teams/${teamId}/users/${userID}/global_blacklists/email${query}`,
  )
}

function getGlobalLinkedInBlacklist(teamId, userID, query) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/teams/${teamId}/users/${userID}/global_blacklists/linkedin${query}`,
  )
}

function getLinkedInBlacklist(userID, accountID, query) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/blacklists/linkedin${query}`,
  )
}

function importBlacklistCSV(userID, accountID, data) {
  const { file, type, comparisonType, source } = data
  const formData = new FormData()
  formData.append("files", file)
  formData.append("type", type)
  formData.append("comparisonType", comparisonType)
  formData.append("source", source)

  return axios.post(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/blacklists/import_csv`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  )
}
function importGlobalBlacklistCSV(teamID, userID, data) {
  const { file, type, comparisonType, source } = data
  const formData = new FormData()
  formData.append("files", file)
  formData.append("type", type)
  formData.append("comparisonType", comparisonType)
  formData.append("source", source)

  return axios.post(
    `${config.REACT_APP_BACKEND_URL}/teams/${teamID}/users/${userID}/global_blacklists/import_csv`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  )
}

export default {
  addToBlacklist,
  addToGlobalBlacklist,
  deleteBlacklist,
  deleteGlobalBlacklist,
  getEmailBlacklist,
  getGlobalEmailBlacklist,
  getGlobalLinkedInBlacklist,
  getLinkedInBlacklist,
  importBlacklistCSV,
  importGlobalBlacklistCSV,
}
