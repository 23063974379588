import "./Tab.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

const Tab = ({ label, isActive, onClick, className, disabled }) => {
  const tabWrapperClasName = classNames("tab-wrapper", {
    "tab-wrapper__active-tab": isActive,
    "tab-wrapper__disabled": disabled,
    [className]: className,
  })

  return (
    <div className={tabWrapperClasName} onClick={onClick}>
      <span className="tab-wrapper__label large">{label}</span>
    </div>
  )
}

Tab.propTypes = {
  label: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
}

Tab.defaultProps = {
  isActive: false,
  onClick: () => {},
  className: undefined,
  disabled: false,
}

export default Tab
