import "./HelpCenter.scss"

import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"

import { useConfig } from "../../../utils/hook"
import { isNotSkyleadFromENV } from "../../../utils/whitelabel-utils"
import SvgIcon from "../SvgIcon"

const HelpCenter = ({
  link,
  helpLabel,
  hover,
  whiteTooltip,
  urlLabel,
  chatLabel,
  backToCampaignLabel,
  loginLayout,
}) => {
  const config = useConfig()

  const [newLink, setNewLink] = useState({})

  const { whitelabelId } = useSelector(({ user }) => user.profile)

  useEffect(() => {
    if (isNotSkyleadFromENV()) {
      setNewLink(link.multilead)
    } else {
      setNewLink(link.skylead)
    }
  }, [whitelabelId, link])

  if (!loginLayout) {
    if (!whitelabelId || config.REACT_APP_HIDE_EXPLANATION_VIDEOS || !newLink) return null
  }

  if (whiteTooltip) {
    return (
      <div>
        <span
          onClick={e => {
            e.stopPropagation()
            window.open(newLink)
          }}
        >
          <div>
            {urlLabel && whiteTooltip && (
              <span className="helper-hover-text-size">
                How to Convert Saved Search URL to a regular URL?
              </span>
            )}

            {chatLabel && (
              <span className="helper-hover-text-size">
                Label your lead simply by clicking the Label you want to add. You can put as many
                labels as you want, and also remove them easily just by clicking on them again.
              </span>
            )}

            {backToCampaignLabel && (
              <span className="helper-hover-text-size">
                In order to save your time and automate your day-to-day LinkedIn activities, we have
                implemented the option of getting leads back to the campaign after they have already
                replied to your message.
              </span>
            )}

            <span
              onClick={e => {
                e.stopPropagation()
                window.open(newLink)
              }}
            >
              <p className="help-center-tooltip-label">Learn more</p>
            </span>
          </div>
        </span>
      </div>
    )
  }

  const hoverText = hover ? "Check help center article" : ""

  return (
    <span>
      {newLink && (
        <div
          className="help-center-wrapper"
          onClick={e => {
            e.stopPropagation()
            window.open(newLink)
          }}
        >
          {helpLabel && <div className="help-center-text-color small-bold">Visit help center</div>}
          <SvgIcon
            icon="external-link-16"
            className="help-center-icon"
            tooltipText={hoverText}
            tooltipTop={60}
            tooltipLeft={50}
            customStyle={{ width: 130, textAlign: "center" }}
            caretBottom
          />
        </div>
      )}
    </span>
  )
}

HelpCenter.propTypes = {
  link: PropTypes.instanceOf(Object).isRequired,
  helpLabel: PropTypes.bool,
  hover: PropTypes.bool,
  whiteTooltip: PropTypes.bool,
  urlLabel: PropTypes.bool,
  chatLabel: PropTypes.bool,
  backToCampaignLabel: PropTypes.bool,
  loginLayout: PropTypes.bool,
}

HelpCenter.defaultProps = {
  helpLabel: false,
  hover: false,
  whiteTooltip: false,
  urlLabel: false,
  chatLabel: false,
  backToCampaignLabel: false,
  loginLayout: false,
}

export default HelpCenter
