import "./LoadingPage.scss"

import React from "react"

import Loader from "../../atoms/Loader"

const LoadingPage = () => {
  return (
    <div className="loading-page-wrapper">
      <div className="loading-page-inner-wrapper">
        <Loader large />
      </div>
    </div>
  )
}
LoadingPage.propTypes = {}
LoadingPage.defaultProps = {}

export default React.memo(LoadingPage)
