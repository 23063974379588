const getNavHeight = () => {
  return document.querySelector("nav")?.getBoundingClientRect()?.height || 0
}

const setTheme = () => {
  const themeFromLocalStorage = localStorage.getItem("theme")

  if (!["light-theme", "dark-theme"].includes(themeFromLocalStorage)) {
    localStorage.setItem("theme", "light-theme")
    document.body.className = "light-theme"
  } else {
    document.body.className = themeFromLocalStorage
  }
}

const getLogo = config => {
  const theme = localStorage.getItem("theme")
  return theme === "dark-theme"
    ? config.REACT_APP_LOGO_IMAGE_DARK_THEME
    : config.REACT_APP_LOGO_IMAGE_LIGHT_THEME
}

const isBrowser = browserName => {
  return navigator.userAgent.indexOf(browserName) !== -1
}

const getMaxOnPage = () => {
  return localStorage.getItem("maxOnPage") || 20
}

export { getLogo, getMaxOnPage, getNavHeight, isBrowser, setTheme }
