import ACTIONS from "../../constants/ACTIONS"

/**
 * Initializes form data
 * @param {Object} formData - initial data for the form
 * @returns {Object} - Action
 */
function initForm(formData = {}) {
  return {
    type: ACTIONS.INIT_FORM,
    formData,
  }
}

/**
 * Clears form data
 * @returns {Object} - Action
 */
function clearForm() {
  return {
    type: ACTIONS.CLEAR_FORM,
  }
}

/**
 * Set errors for form fields
 * @param {Object} formErrors - errors for form fields
 */
function setFormErrors(formErrors) {
  return {
    type: ACTIONS.SET_FORM_ERRORS,
    formErrors,
  }
}

/**
 * Set complex form errors in form fields
 * @param {Object} formErrors - errors for form fields
 */

function setComplexFormErrors(complexStepsError) {
  return {
    type: ACTIONS.SET_COMPLEX_FORM_ERRORS,
    complexStepsError,
  }
}

/**
 * clear errors for form field
 */
function clearFormError(field) {
  return {
    type: ACTIONS.CLEAR_FORM_ERROR,
    field,
  }
}

/**
 * clear errors for complex steps form field
 */
function clearComplexStepsError(index) {
  return {
    type: ACTIONS.CLEAR_COMPLEX_STEPS_ERROR,
    index,
  }
}
/**
 * clear errors for form fields
 */
function clearFormErrors() {
  return {
    type: ACTIONS.CLEAR_FORM_ERRORS,
  }
}

/**
 * Updates one form field
 * @param {string} field - name of the field to update
 * @param {any} value - value to update
 * @returns {Object} - Action
 */
function updateFormField(field, value) {
  return {
    type: ACTIONS.UPDATE_FORM_FIELD,
    field,
    value,
  }
}

/**
 * Updates multiple form fields
 * @param {Object} field - form fields to update
 * @returns {Object} - Action
 */
function updateFormFields(fields) {
  return {
    type: ACTIONS.UPDATE_FORM_FIELDS,
    fields,
  }
}

export {
  initForm,
  clearForm,
  setFormErrors,
  setComplexFormErrors,
  clearFormError,
  clearComplexStepsError,
  clearFormErrors,
  updateFormField,
  updateFormFields,
}
