import ACTIONS from "../../constants/ACTIONS"
import blacklistService from "../../services/blacklist-service"

function getActionSuccess(actionType, payload) {
  return { type: actionType, payload }
}

function addToBlacklist(data) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      await blacklistService.addToBlacklist(userID, accountID, data)
      dispatch(getActionSuccess(ACTIONS.ADD_TO_BLACKLIST))
      return true
    } catch (error) {
      return false
    }
  }
}

function addToGlobalBlacklist(data) {
  return async (dispatch, getState) => {
    try {
      const { selectedTeam } = getState().teamManagement
      const userID = getState().user.profile.id
      await blacklistService.addToGlobalBlacklist(selectedTeam.teamId, userID, data)
      dispatch(getActionSuccess(ACTIONS.ADD_TO_GLOBAL_BLACKLIST))
      return true
    } catch (error) {
      return false
    }
  }
}

function deleteBlacklist(data) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      await blacklistService.deleteBlacklist(userID, accountID, data)
      dispatch(getActionSuccess(ACTIONS.DELETE_BLACKLIST))
      return true
    } catch (error) {
      return false
    }
  }
}

function deleteGlobalBlacklist(data) {
  return async (dispatch, getState) => {
    try {
      const { selectedTeam } = getState().teamManagement
      const userID = getState().user.profile.id
      await blacklistService.deleteGlobalBlacklist(selectedTeam.teamId, userID, data)
      dispatch(getActionSuccess(ACTIONS.DELETE_GLOBAL_BLACKLIST))
      return true
    } catch (error) {
      return false
    }
  }
}

function getEmailBlacklist(query = "") {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      const result = await blacklistService.getEmailBlacklist(userID, accountID, query)
      const emailBlacklist = result.data.result.result
      dispatch(getActionSuccess(ACTIONS.GET_EMAIL_BLACKLIST, emailBlacklist))
    } catch (error) {}
  }
}

function getGlobalEmailBlacklist(query = "") {
  return async (dispatch, getState) => {
    try {
      const { selectedTeam } = getState().teamManagement
      const userID = getState().user.profile.id
      const result = await blacklistService.getGlobalEmailBlacklist(
        selectedTeam.teamId,
        userID,
        query,
      )
      const globalEmailBlacklist = result.data.result.result
      dispatch(getActionSuccess(ACTIONS.GET_GLOBAL_EMAIL_BLACKLIST, globalEmailBlacklist))
    } catch (error) {}
  }
}

function getGlobalLinkedInBlacklist(query = "") {
  return async (dispatch, getState) => {
    try {
      const { selectedTeam } = getState().teamManagement
      const userID = getState().user.profile.id
      const result = await blacklistService.getGlobalLinkedInBlacklist(
        selectedTeam.teamId,
        userID,
        query,
      )
      const globalLinkedinBlacklist = result.data.result.result
      dispatch(getActionSuccess(ACTIONS.GET_GLOBAL_LINKEDIN_BLACKLIST, globalLinkedinBlacklist))
    } catch (error) {}
  }
}

function getLinkedInBlacklist(query = "") {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      const result = await blacklistService.getLinkedInBlacklist(userID, accountID, query)
      const linkedinBlacklist = result.data.result.result
      dispatch(getActionSuccess(ACTIONS.GET_LINKEDIN_BLACKLIST, linkedinBlacklist))
    } catch (error) {}
  }
}

function importBlacklistCSV(data) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      await blacklistService.importBlacklistCSV(userID, accountID, data)
      dispatch(getActionSuccess(ACTIONS.IMPORT_BLACKLIST_CSV))
      return true
    } catch (error) {
      return false
    }
  }
}

function importGlobalBlacklistCSV(data) {
  return async (dispatch, getState) => {
    try {
      const { selectedTeam } = getState().teamManagement
      const userID = getState().user.profile.id
      await blacklistService.importGlobalBlacklistCSV(selectedTeam.teamId, userID, data)
      dispatch(getActionSuccess(ACTIONS.IMPORT_BLACKLIST_CSV))
      return true
    } catch (error) {
      return false
    }
  }
}

export {
  addToBlacklist,
  addToGlobalBlacklist,
  deleteBlacklist,
  deleteGlobalBlacklist,
  getEmailBlacklist,
  getGlobalEmailBlacklist,
  getGlobalLinkedInBlacklist,
  getLinkedInBlacklist,
  importBlacklistCSV,
  importGlobalBlacklistCSV,
}
