import "./Status.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import SvgIcon from "../SvgIcon"

export const STATUS_COLOR = {
  GREEN: "green",
  YELLOW: "yellow",
  RED: "red",
  GRAY: "gray",
}

const Status = ({ text, color, icon, statusTooltip, setTooltip, closeTooltip }) => {
  const statWrapperClass = classNames("status-wrapper", "small", {
    "stat-wrapper-green": color === STATUS_COLOR.GREEN,
    "stat-wrapper-yellow": color === STATUS_COLOR.YELLOW,
    "stat-wrapper-red": color === STATUS_COLOR.RED,
    "stat-wrapper-gray": color === STATUS_COLOR.GRAY,
  })

  return (
    <span
      className={statWrapperClass}
      onMouseEnter={e => {
        setTooltip({
          event: e,
          text: statusTooltip?.tooltipText,
          topPos: statusTooltip?.tooltipTop,
          leftPos: statusTooltip?.tooltipLeft,
        })
      }}
      onMouseLeave={closeTooltip}
    >
      <SvgIcon
        icon={icon}
        className="status-icon"
        tooltipText={statusTooltip?.text}
        tooltipLeft={statusTooltip?.tooltipLeft}
        tooltipTop={statusTooltip?.tooltipTop}
      />
      {text}
    </span>
  )
}

Status.propTypes = {
  text: PropTypes.string,
  color: PropTypes.oneOf(["green", "yellow", "red", "gray"]),
  icon: PropTypes.string,
  statusTooltip: PropTypes.shape({
    tooltipText: PropTypes.string,
    tooltipTop: PropTypes.number,
    tooltipLeft: PropTypes.number,
  }),
  setTooltip: PropTypes.func,
  closeTooltip: PropTypes.func,
}

Status.defaultProps = {
  text: "",
  color: "gray",
  icon: undefined,
  statusTooltip: null,
  setTooltip: () => {},
  closeTooltip: () => {},
}

export default Status
