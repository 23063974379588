/* eslint-disable react/no-unused-prop-types */
import "./Modal.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"

import { closeModal, showModal } from "../../../redux/actions"
import Footer, { FooterVariant } from "../../molecules/Footer"
import LoadingPage from "../../pages/LoadingPage"

export const ModalVariant = {
  SMALL: "small",
  LARGE: "large",
}

const Modal = modalDataProps => {
  const dispatch = useDispatch()
  const {
    show = false,
    type = modalDataProps.type,
    title = modalDataProps.title,
    content = modalDataProps.content,
    footerVariant = modalDataProps.footerVariant,
    primaryBtn = modalDataProps.primaryBtn,
    secondaryBtn = modalDataProps.secondaryBtn,
    tertiaryBtn = modalDataProps.tertiaryBtn,
    noHideOnSubmit = modalDataProps.noHideOnSubmit,
    showPreviuos = modalDataProps.showPreviuos,
    previousData = modalDataProps.previousData,
    headerChildren = modalDataProps.headerChildren,
    headerClass = modalDataProps.headerClass,
  } = useSelector(state => state.app.modalData)

  const { loading = modalDataProps.loading } = useSelector(state => state.app.modalLoader)
  const {
    primaryBtnRedux,
    secondaryBtnRedux,
    tertiaryBtnRedux,
    headerClassRedux,
    titleRedux,
    footerVariantRedux,
    noHideOnSubmitRedux,
  } = useSelector(state => state.forms.formData)

  const showPreviuosModal = () => {
    dispatch(
      showModal(
        previousData.type,
        previousData.title,
        previousData.content,
        previousData.footerVariant,
        previousData.primaryBtn,
        previousData.secondaryBtn,
        previousData.tertiaryBtn,
        previousData.noHideOnSubmit,
        previousData.showPreviuos,
        previousData.headerChildren,
        previousData.headerClass,
      ),
    )
  }

  const onClickPrimaryBtn = event => {
    if (primaryBtnRedux?.onClick) {
      primaryBtnRedux.onClick()
    } else if (primaryBtn?.onClick) {
      primaryBtn.onClick(event)
    }

    if (!noHideOnSubmit && !noHideOnSubmitRedux) {
      dispatch(closeModal())
    }
    if (showPreviuos && footerVariant === FooterVariant.ACKNOWLEDGMENT) {
      showPreviuosModal()
    }
  }

  const onClickSecondaryBtn = () => {
    if (secondaryBtnRedux?.onClick) {
      secondaryBtnRedux.onClick()
    } else if (secondaryBtn?.onClick) {
      secondaryBtn.onClick()
    } else {
      dispatch(closeModal())
    }

    if (showPreviuos) {
      showPreviuosModal()
    }
  }

  const onClickTertiaryBtn = () => {
    if (tertiaryBtnRedux?.onClick) {
      tertiaryBtnRedux.onClick()
    } else if (tertiaryBtn?.onClick) {
      tertiaryBtn.onClick()
    } else {
      dispatch(closeModal())
    }
  }

  const buttons = useMemo(() => {
    const buttonsArray = []

    if (tertiaryBtn || tertiaryBtnRedux) {
      buttonsArray.push({
        label: tertiaryBtnRedux?.label || tertiaryBtn?.label || "Cancel",
        onClick: onClickTertiaryBtn,
        disabled: tertiaryBtnRedux?.disabled ?? tertiaryBtn?.disabled ?? false,
      })
    }
    if (secondaryBtn || secondaryBtnRedux) {
      buttonsArray.push({
        label: secondaryBtnRedux?.label || secondaryBtn?.label || "Cancel",
        onClick: onClickSecondaryBtn,
        disabled: secondaryBtnRedux?.disabled ?? secondaryBtn?.disabled ?? false,
        variant: secondaryBtnRedux?.variant || secondaryBtn?.variant,
      })
    }
    if (primaryBtn || primaryBtnRedux) {
      buttonsArray.push({
        label: primaryBtnRedux?.label || primaryBtn?.label || "Cancel",
        onClick: onClickPrimaryBtn,
        disabled: primaryBtnRedux?.disabled ?? primaryBtn?.disabled ?? false,
      })
    }
    return buttonsArray
  }, [primaryBtn, secondaryBtn, tertiaryBtn, primaryBtnRedux, secondaryBtnRedux, tertiaryBtnRedux])

  const modalWrapperClassnames = classNames(`modal-wrapper`, {
    hide: !(show || modalDataProps.show || false),
  })

  const modalContainerClassnames = classNames(`modal-container`, {
    small: type === ModalVariant.SMALL,
    large: type === ModalVariant.LARGE,
  })

  const modalContentClassnames = classNames(`content`, {
    "content-without-padding": !(footerVariant || footerVariantRedux),
  })

  const headerClassnames = classNames("header", {
    [headerClass]: headerClass,
    [headerClassRedux]: headerClassRedux,
  })

  const renderTitle = useMemo(() => {
    if (typeof title === "string") {
      return <h1 className="title">{titleRedux || title}</h1>
    }

    return <div className="title-wrapper">{titleRedux || title}</div>
  }, [title, titleRedux])

  return (
    <div className={modalWrapperClassnames}>
      <div className={modalContainerClassnames}>
        <div className={headerClassnames}>
          {renderTitle}
          {headerChildren && { ...headerChildren }}
        </div>
        {loading && (
          <div>
            <LoadingPage />
          </div>
        )}
        <div className={modalContentClassnames}>{content}</div>
        <div className="footer">
          {(footerVariant || footerVariantRedux) && (
            <Footer type={footerVariantRedux || footerVariant} buttons={buttons} bottomRadius />
          )}
        </div>
      </div>
    </div>
  )
}

Modal.propTypes = {
  show: PropTypes.bool,
  type: PropTypes.oneOf(Object.values(ModalVariant)),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  content: PropTypes.node,
  footerVariant: PropTypes.oneOf(Object.values(FooterVariant)),
  primaryBtn: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
  }),
  secondaryBtn: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
  }),
  tertiaryBtn: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
  }),
  noHideOnSubmit: PropTypes.bool,
  showPreviuos: PropTypes.bool,
  previousData: PropTypes.instanceOf(Object),
  headerClass: PropTypes.string,
}

Modal.defaultProps = {
  show: false,
  type: ModalVariant.SMALL,
  title: "",
  content: null,
  footerVariant: undefined,
  primaryBtn: undefined,
  secondaryBtn: undefined,
  tertiaryBtn: undefined,
  noHideOnSubmit: false,
  showPreviuos: false,
  previousData: {},
  headerClass: "",
}

export default Modal
