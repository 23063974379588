import "./CustomToggle.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

const CustomToggle = React.forwardRef(({ children, onClick, style, disabled, readOnly }, ref) => {
  const handleClick = e => {
    // if (readOnly || disabled) {
    //   return
    // }

    e.preventDefault()
    onClick(e)
  }

  const customToggleSpanClass = classNames("d-flex dropdown-select-toggle align-items-center", {
    "readonly-dropdown": readOnly,
    "disabled-dropdown": disabled,
  })

  return (
    <span className={customToggleSpanClass} onClick={handleClick} style={style} ref={ref}>
      {children}
    </span>
  )
})

CustomToggle.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  style: PropTypes.instanceOf(Object),
}

CustomToggle.defaultProps = {
  onClick: () => {},
  style: {},
}

export default CustomToggle
