import { getCampaignType } from "./campaign-utils"

const firstConnectionsSalesNavNewUrl = "text:1st%20Degree%20Connections"
const secondConnectionSalesNavNewUrl = "text:2nd%20Degree%20Connections"
const thirdConnectionSalesNavNewUrl = "text:3rd%20Degree%2B%20Connections"
const groupConnectionSalesNavNewUrl = "text:Group%20members"

const recognizedBasicConnection = (selectedFacetNetworks, newState) => {
  selectedFacetNetworks.forEach(facetNetwork => {
    switch (facetNetwork) {
      case "F":
        newState = {
          ...newState,
          firstConnection: true,
        }
        break
      case "S":
        newState = {
          ...newState,
          secondConnection: true,
        }
        break
      case "O":
        newState = {
          ...newState,
          thirdConnection: true,
        }
        break
      case "A":
        newState = {
          ...newState,
          groupConnection: true,
        }
        break

      default:
        break
    }
  })

  return newState
}

const determineCampaignType = (value, dashboard, linkedinSubscriptionId, newState) => {
  if (value.includes("linkedin.com/search")) {
    if (dashboard !== "1") {
      newState = {
        ...newState,
        dashboard: "1",
        campaignType: getCampaignType("1"),
      }
    }
  } else if (value.includes("linkedin.com/sales/lists/people")) {
    if (dashboard !== "leads-list") {
      newState = {
        ...newState,
        dashboard: "leads-list",
        campaignType: getCampaignType("leads-list"),
      }
    }
  } else if (value.includes("linkedin.com/sales") && linkedinSubscriptionId === 2) {
    if (dashboard !== "2") {
      newState = {
        ...newState,
        dashboard: "2",
        campaignType: getCampaignType("2"),
      }
    }
  } else if (value.includes("linkedin.com/posts")) {
    if (dashboard !== "post-engagement") {
      newState = {
        ...newState,
        dashboard: "post-engagement",
        campaignType: getCampaignType("post-engagement"),
      }
    }
  } else if (value.includes("linkedin.com/talent/search")) {
    if (dashboard !== "recruiter" && linkedinSubscriptionId === 3) {
      newState = {
        ...newState,
        dashboard: "recruiter",
        campaignType: getCampaignType("recruiter"),
      }
    }
  } else if (value.includes("linkedin.com/talent/hire") && linkedinSubscriptionId === 3) {
    newState = {
      ...newState,
      dashboard: "recruiter-pipeline",
      campaignType: getCampaignType("recruiter-pipeline"),
    }
  }
  return newState
}

const recognizedCampaignType = campaignUrl => {
  let campaignType = {}
  if (campaignUrl.includes("linkedin.com/search")) {
    campaignType = {
      dashboard: "1",
      campaignType: getCampaignType("1"),
    }
  }
  if (campaignUrl.includes("linkedin.com/sales")) {
    campaignType = { dashboard: "2", campaignType: getCampaignType("2") }
  }
  if (campaignUrl.includes("linkedin.com/sales/lists/people")) {
    campaignType = {
      dashboard: "leads-list",
      campaignType: getCampaignType("leads-list"),
    }
  }
  if (campaignUrl.includes("linkedin.com/posts")) {
    campaignType = {
      dashboard: "post-engagement",
      campaignType: getCampaignType("post-engagement"),
    }
  }
  if (campaignUrl.includes("linkedin.com/talent/search")) {
    campaignType = { dashboard: "recruiter", campaignType: getCampaignType("recruiter") }
  }
  if (campaignUrl.includes("linkedin.com/talent/hire")) {
    campaignType = {
      dashboard: "recruiter-pipeline",
      campaignType: getCampaignType("recruiter-pipeline"),
    }
  }
  return campaignType
}

export {
  firstConnectionsSalesNavNewUrl,
  secondConnectionSalesNavNewUrl,
  thirdConnectionSalesNavNewUrl,
  groupConnectionSalesNavNewUrl,
  recognizedBasicConnection,
  determineCampaignType,
  recognizedCampaignType,
}
