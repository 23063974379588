import axios from "axios"

import config from "./config"

const sendErrors = async (message, additional = {}) => {
  try {
    await axios.post(`${config.REACT_APP_BACKEND_URL}/system/logs/errors`, {
      message,
      additional: JSON.stringify(additional),
    })
  } catch (error) {}
}

const sendWarnings = async (message, additional = {}) => {
  try {
    await axios.post(`${config.REACT_APP_BACKEND_URL}/system/logs/warnings`, {
      message,
      additional: JSON.stringify(additional),
    })
  } catch (error) {}
}
const sendInfos = async (message, additional = {}) => {
  try {
    await axios.post(`${config.REACT_APP_BACKEND_URL}/system/logs/infos`, {
      message,
      additional: JSON.stringify(additional),
    })
  } catch (error) {}
}

export default {
  sendErrors,
  sendWarnings,
  sendInfos,
}
