const lettersArray = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
]

const AB_TESTING_TABS_COUNT = 5

const AB_TESTING_ELEMENTS = ["connect", "message", "email", "inMail"]

const specificLinkedInSettingsConstants = [
  {
    name: "Discover LinkedIn Premium accounts only",
    field: "onlyPremium",
    showFor: ["1", "2", "recruiter", "recruiter-pipeline"],
    subtitle: "Discover and connect with only LinkedIn Premium accounts to narrow your search.",
  },
  {
    name: "Discover leads with open profile status only",
    field: "onlyOpenInmail",
    showFor: ["1", "2", "recruiter", "recruiter-pipeline"],
    subtitle: "Discover and connect exclusively with leads who have an Open profile status.",
  },
  {
    name: "Collect contact information",
    field: "getPersonalInfo",
    showFor: [
      "1",
      "2",
      "recruiter",
      "recruiter-pipeline",
      "import",
      "csv",
      "post-engagement",
      "leads-list",
    ],
    subtitle:
      "Fetch publicly available contact information from leads' LinkedIn profiles such as email, phone number or website. This option auto-adds a View profile after the Invite to connect step into the campaign.",
  },
  {
    name: "Remove leads with pending connection requests",
    field: "noPendingConnections",
    showFor: ["1", "2"],
    subtitle: "Choose whether you want to remove leads with pending invites you've already sent.",
  },
  {
    name: "Refresh search automatically",
    field: "autoReuse",
    showFor: ["1", "2", "recruiter", "post-engagement", "recruiter-pipeline", "leads-list"],
    subtitle:
      "Automatically refresh your search result and add newly found leads that match the same search parameters.",
  },
]

const targetingOptionsConstants = [
  {
    name: "Include leads that replied to your messages",
    field: "sendPrevious",
    showFor: ["1", "2", "recruiter", "recruiter-pipeline", "import", "csv", "leads-list"],
    subtitle: "Include all leads you've had a chat with via LinkedIn message, InMail, or email.",
  },
  {
    name: "Include leads found in other campaigns",
    field: "includeGloballyTargetedLeads",
    showFor: [
      "1",
      "2",
      "recruiter",
      "recruiter-pipeline",
      "import",
      "csv",
      "post-engagement",
      "leads-list",
    ],
    subtitle: "Include leads found in campaigns from your team seats.",
  },
  {
    name: "Discover new leads only",
    field: "onlyUniqueLeads",
    showFor: ["1", "2", "recruiter", "recruiter-pipeline", "post-engagement", "leads-list"],
    subtitle: "We won't include leads already discovered in other campaigns from your seat.",
  },
]

const emailTrackingPreferenceConstants = [
  {
    name: "Track email link clicks",
    field: "trackEmailsClicks",
    showFor: [
      "1",
      "2",
      "recruiter",
      "recruiter-pipeline",
      "import",
      "csv",
      "post-engagement",
      "leads-list",
    ],
    subtitle: "Keep an eye on how many leads click the links in your emails.",
  },
  {
    name: "Track opened emails",
    field: "trackEmailsOpens",
    showFor: [
      "1",
      "2",
      "recruiter",
      "recruiter-pipeline",
      "import",
      "csv",
      "post-engagement",
      "leads-list",
    ],
    subtitle: "See how many leads are opening your emails.",
  },
  {
    name: "Send text-only emails (no HTML)",
    field: "textOnlyEmails",
    showFor: [
      "1",
      "2",
      "post-engagement",
      "recruiter",
      "leads-list",
      "recruiter-pipeline",
      "import",
      "csv",
    ],
    subtitle:
      "Send email messages that only include text without images, graphics, or formatting. If you turn this option on, you won't be able to track link click and open rates.",
  },
]

const defaultAutoReuseInterval = 30

const maxFileSize = 6 * 1024 * 1024

export {
  lettersArray,
  AB_TESTING_TABS_COUNT,
  AB_TESTING_ELEMENTS,
  specificLinkedInSettingsConstants,
  targetingOptionsConstants,
  emailTrackingPreferenceConstants,
  defaultAutoReuseInterval,
  maxFileSize,
}
