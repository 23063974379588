import ACTIONS from "../../constants/ACTIONS"

const initialState = {
  selectedTeam: null,
  teamRoles: [],
  platformRoles: [],
  permissions: [],
  userTeams: { items: [], count: 0, sumCount: 0 },
  teamAccounts: { items: [], count: 0, sumCount: 0 },
  teamMembers: { items: [], count: 0 },
  coWorkers: { items: [], count: 0 },
  coWorkerTeamsForInvite: { items: [] },
  platformAdmins: { items: [], count: 0 },
  teamSearch: "",
  selectedTeamAccount: {},
}

export default function teamManagementReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.GET_USER_TEAMS: {
      let newItems
      if (action.payload.offset === 0) {
        newItems = [...action.payload.userTeams.items]
      } else {
        newItems = [...state.userTeams.items, ...action.payload.userTeams.items]
      }
      return {
        ...state,
        userTeams: {
          items: newItems,
          count: action.payload.userTeams.count,
          sumCount: action.payload.userTeams.sumCount,
        },
        teamSearch: action.payload.search || "",
      }
    }

    case ACTIONS.CLEAR_USER_TEAMS: {
      return {
        ...state,
        userTeams: { items: [], count: 0, sumCount: 0 },
      }
    }

    case ACTIONS.GET_TEAM_ACCOUNTS: {
      return {
        ...state,
        teamAccounts: {
          items: action.payload.items,
          count: action.payload.count,
          sumCount: action.payload.sumCount,
        },
      }
    }

    case ACTIONS.CLEAR_TEAM_ACCOUNTS: {
      return {
        ...state,
        teamAccounts: { items: [], count: 0, sumCount: 0 },
      }
    }

    case ACTIONS.GET_USER_TEAM: {
      const { team } = action.payload
      const isUserTeamsEmpty = !state.userTeams.count
      return {
        ...state,
        userTeams: {
          count: isUserTeamsEmpty ? 1 : state.userTeams.count,
          sumCount: isUserTeamsEmpty ? 1 : state.userTeams.sumCount,
          items: isUserTeamsEmpty
            ? [team]
            : state.userTeams.items.map(item => (item.teamId === team.teamId ? team : item)),
        },
      }
    }

    case ACTIONS.GET_TEAM_ROLES: {
      return {
        ...state,
        teamRoles: action.payload,
      }
    }

    case ACTIONS.GET_PLATFORM_ROLES: {
      return {
        ...state,
        platformRoles: action.payload,
      }
    }

    case ACTIONS.GET_PERMISSIONS: {
      return {
        ...state,
        permissions: action.payload,
      }
    }

    case ACTIONS.SET_SELECTED_TEAM: {
      return {
        ...state,
        selectedTeam: action.payload,
      }
    }

    case ACTIONS.GET_TEAM_MEMBERS: {
      return {
        ...state,
        teamMembers: { items: action.payload.items, count: action.payload.count },
      }
    }

    case ACTIONS.GET_CO_WORKERS: {
      return {
        ...state,
        coWorkers: { items: action.payload.items, count: action.payload.count },
      }
    }

    case ACTIONS.GET_PLATFORM_ADMINS: {
      return {
        ...state,
        platformAdmins: { items: action.payload.items, count: action.payload.count },
      }
    }

    case ACTIONS.GET_GLOBAL_STATUS_FOR_ACCOUNT: {
      return {
        ...state,
        teamAccounts: {
          ...state.teamAccounts,
          items: [
            ...state.teamAccounts.items.map(teamAccount => {
              if (+teamAccount.id === +action.accountID) {
                return {
                  ...teamAccount,
                  accountGlobalStatusId: action.globalStatus.globalStatusId,
                }
              }

              return { ...teamAccount }
            }),
          ],
        },
      }
    }

    case ACTIONS.TOGGLE_SUCCESS: {
      const newUserTeamsItems = state.userTeams.items.map(team => {
        if (team.teamId === action.payload.teamId) {
          return { ...team, canManageTeamMembers: action.payload.canManageTeamMembers }
        }
        return { ...team }
      })
      return {
        ...state,
        userTeams: { ...state.userTeams, items: newUserTeamsItems },
      }
    }

    case ACTIONS.USER_LOGOUT: {
      return initialState
    }

    case ACTIONS.SET_SELECTED_TEAM_ACCOUNT: {
      return {
        ...state,
        selectedTeamAccount: action.payload,
      }
    }

    case ACTIONS.GET_CO_WORKER_TEAMS_FOR_INVITE: {
      return {
        ...state,
        coWorkerTeamsForInvite: { items: action.payload.items },
      }
    }

    case ACTIONS.CLEAN_CO_WORKER_TEAMS_FOR_INVITE: {
      return {
        ...state,
        coWorkerTeamsForInvite: { items: [] },
      }
    }

    default:
      return { ...state }
  }
}
