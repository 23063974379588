import { getConfigEnv } from "../utils/config"

function checkVitallyRequirements() {
  if (!window.Vitally) {
    return false
  }

  // if in production, track event
  if (process.env.NODE_ENV !== "production") {
    return false
  }

  // if analytics are disabled, don't track
  const config = getConfigEnv(window._env_)
  if (config.REACT_APP_NO_ANALYTICS) {
    return false
  }

  // if no credentials, don't track
  const [[, token]] = window.Vitally.credentials
  if (token === "undefined" || !token) {
    return false
  }

  return true
}

function initNPS(productName) {
  const isVitallyReady = checkVitallyRequirements()
  if (!isVitallyReady) {
    return
  }

  return window.Vitally.nps("survey", {
    productName,
    delay: 5000,
  })
}

/**
 * Set event tracking
 *
 * @returns {Promise}
 */
function track(event, userId, teamId, userType, properties = {}) {
  try {
    const isVitallyReady = checkVitallyRequirements()
    if (!isVitallyReady) {
      return
    }

    // if no user id, team id, or user type, don't track
    if (!userId || !teamId || !userType) {
      return
    }

    const userIdPrepared = `${userId}-${teamId}-${userType}`
    return window.Vitally.track({
      event,
      userId: userIdPrepared,
      properties,
    })
  } catch (error) {
    // .
  }
}

export default {
  track,
  initNPS,
}
