import "./ContentSwitcher.scss"

import React from "react"
import { v4 as uuidv4 } from "uuid"

import ContentSwitcherItem from "../ContentSwitcherItem"

const ContentSwitcher = ({ items, onClick }) => {
  return (
    <div className="content-switcher-row">
      {items.map(item => (
        <ContentSwitcherItem
          key={uuidv4()}
          icon={item.icon}
          text={item.text}
          active={item.active}
          disabled={item.disabled}
          stretched={item.stretched}
          onClick={() => onClick(item)}
          tooltipData={item.tooltip}
        />
      ))}
    </div>
  )
}

export default ContentSwitcher
