/*
 * Created on Thu Sep 08 2022 by Ivan Kesler
 *
 */

/**
 * Internal function used to manipulate the RELATIONSHIP flags of the LinkedIn query object
 * @param query The query object to manipulate
 * @param val The new value to set for the flag
 * @param id The id of the flag that is to be set
 * @returns {{filters}|*} The *new* resulting query object
 */
const setAnyConnection = (query, val, id) => {
  if (!query.filters) {
    query.filters = []
  }
  let relationship = query.filters.find(x => x.type === "RELATIONSHIP")
  if (!relationship) {
    relationship = { type: "RELATIONSHIP", values: [] }
    query.filters.push(relationship)
  }
  if (!val) {
    relationship.values = relationship.values.filter(x => x.id !== id)
  } else {
    let targetedRelationship = relationship.values.find(x => x.id === id)
    if (!targetedRelationship) {
      targetedRelationship = { id }
      relationship.values.push(targetedRelationship)
    }
    targetedRelationship.selectionType = "INCLUDED"
  }
  return query
}

/**
 * Function used to set the value of the firstConnections parameter to the desired value (true or false)
 * @param query The LinkedIn query object to apply the operation to
 * @param val The value for the firstConnections parameter
 */
const setFirstConnections = (query, val) => setAnyConnection(query, val, "F")

/**
 * Function used to set the value of the secondConnections parameter to the desired value (true or false)
 * @param query The LinkedIn query object to apply the operation to
 * @param val The value for the secondConnections parameter
 */
const setSecondConnections = (query, val) => setAnyConnection(query, val, "S")

/**
 * Function used to set the value of the thirdConnections parameter to the desired value (true or false)
 * @param query The LinkedIn query object to apply the operation to
 * @param val The value for the thirdConnections parameter
 */
const setThirdConnections = (query, val) => setAnyConnection(query, val, "O")

/**
 * Function used to set the value of the groupConnections parameter to the desired value (true or false)
 * @param query The LinkedIn query object to apply the operation to
 * @param val The value for the groupConnections parameter
 */
const setGroupConnections = (query, val) => setAnyConnection(query, val, "A")

/**
 * Internal function to check if the given query object has a particular RELATIONSHIP flag set
 * @param query The query object to inspect
 * @param id The id of the flag to check for
 * @returns {boolean} True if the flag is set, false otherwise (no filters present, relationship object missing, or set to false)
 */
const hasAnyConnection = (query, id) => {
  return !!(
    query &&
    query.filters &&
    query.filters.find(x => x.type === "RELATIONSHIP") &&
    query.filters.find(x => x.type === "RELATIONSHIP").values.find(x => x.id === id)
  )
}

/**
 * Function used to check if the given query object has the firstConnections flag set
 * @param query The query object to inspect
 * @returns {boolean} True if the flag is set, false otherwise (no filters present, relationship object missing, or set to false)
 */
const hasFirstConnections = query => hasAnyConnection(query, "F")

/**
 * Function used to check if the given query object has the secondConnections flag set
 * @param query The query object to inspect
 * @returns {boolean} True if the flag is set, false otherwise (no filters present, relationship object missing, or set to false)
 */
const hasSecondConnections = query => hasAnyConnection(query, "S")

/**
 * Function used to check if the given query object has the thirdConnections flag set
 * @param query The query object to inspect
 * @returns {boolean} True if the flag is set, false otherwise (no filters present, relationship object missing, or set to false)
 */
const hasThirdConnections = query => hasAnyConnection(query, "O")

/**
 * Function used to check if the given query object has the groupConnections flag set
 * @param query The query object to inspect
 * @returns {boolean} True if the flag is set, false otherwise (no filters present, relationship object missing, or set to false)
 */
const hasGroupConnections = query => hasAnyConnection(query, "A")

export {
  setFirstConnections,
  setSecondConnections,
  setThirdConnections,
  setGroupConnections,
  hasFirstConnections,
  hasSecondConnections,
  hasThirdConnections,
  hasGroupConnections,
}
