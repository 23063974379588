import { store } from "../redux/store"

const isInFreeTrial = allSubscriptions =>
  allSubscriptions[0] && allSubscriptions[0].status === "trialing"

const isPaymentDue = allSubscriptions =>
  allSubscriptions[0] && allSubscriptions[0].status === "past_due"

const getBuySeatsPlans = () => {
  const { pricingPlans } = store.getState().payment

  const { availableSeats } = store.getState().user.profile
  const { formData } = store.getState().forms

  const plans = []
  const newAvailableSeats = []
  pricingPlans.forEach(plan => {
    const planAvailableSeats = availableSeats.find(seat => seat.planId === plan.id)
    const quantity = planAvailableSeats ? planAvailableSeats.quantity : 0
    if (formData[`${plan.name}-seats`] > 0) {
      plans.push({
        planId: +plan.id,
        quantity: +formData[`${plan.name}-seats`],
      })
      newAvailableSeats.push({
        planId: +plan.id,
        quantity: +formData[`${plan.name}-seats`] + +quantity,
      })
    } else {
      newAvailableSeats.push({
        planId: +plan.id,
        quantity: +quantity,
      })
    }
  })

  return {
    plans,
    newAvailableSeats,
  }
}

export { isInFreeTrial, isPaymentDue, getBuySeatsPlans }
