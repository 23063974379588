import axios from "axios"

import config from "../utils/config"

function getTeamsForUser(userID, search) {
  return axios.get(`${config.REACT_APP_BACKEND_URL}/users/${userID}/get_teams_for_user${search}`)
}

function getTeamForUser(userID, teamID) {
  return axios.get(`${config.REACT_APP_BACKEND_URL}/users/${userID}/teams/${teamID}/get_team`)
}

function getTeamAccounts(userID, teamID, search) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/teams/${teamID}/get_team_accounts${search}`,
  )
}

function changeTeamName(userID, teamID, teamName) {
  return axios.patch(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/teams/${teamID}/change_team_name`,
    { teamName },
  )
}

function leaveTeam(userID, teamID) {
  return axios.delete(`${config.REACT_APP_BACKEND_URL}/teams/${teamID}/users/${userID}/leave_team`)
}

function deleteTeam(userID, teamID) {
  return axios.delete(`${config.REACT_APP_BACKEND_URL}/users/${userID}/teams/${teamID}/delete_team`)
}

function getPermissions(userID, teamID) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/teams/${teamID}/users/${userID}/get_permissions`,
  )
}

function getTeamRoles(userID, teamID) {
  return axios.get(`${config.REACT_APP_BACKEND_URL}/teams/${teamID}/users/${userID}/get_roles`)
}

function getPlatformRoles(userID) {
  return axios.get(`${config.REACT_APP_BACKEND_URL}/users/${userID}/get_platform_roles`)
}

function createTeamRole(userID, teamID, data) {
  return axios.post(
    `${config.REACT_APP_BACKEND_URL}/teams/${teamID}/users/${userID}/create_role`,
    data,
  )
}

function createPlatformRole(userID, data) {
  return axios.post(`${config.REACT_APP_BACKEND_URL}/users/${userID}/create_platform_role`, data)
}

function updateTeamRole(userID, teamID, roleID, data) {
  return axios.patch(
    `${config.REACT_APP_BACKEND_URL}/teams/${teamID}/users/${userID}/role/${roleID}/update_role`,
    data,
  )
}

function updatePlatformRole(userID, roleID, data) {
  return axios.patch(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/role/${roleID}/update_platform_role`,
    data,
  )
}

function deleteTeamRole(userID, teamID, roleID) {
  return axios.delete(
    `${config.REACT_APP_BACKEND_URL}/teams/${teamID}/users/${userID}/role/${roleID}/delete_role`,
  )
}

function deletePlatformRole(userID, roleID) {
  return axios.delete(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/role/${roleID}/delete_platform_role`,
  )
}

function createTeam(userID, data) {
  return axios.post(`${config.REACT_APP_BACKEND_URL}/users/${userID}/create_team`, data)
}

function invitePlatformAdmin(userID, data) {
  return axios.post(`${config.REACT_APP_BACKEND_URL}/users/${userID}/invite_platform_admin`, data)
}

function inviteTeamMember(userID, teamID, data) {
  return axios.post(
    `${config.REACT_APP_BACKEND_URL}/teams/${teamID}/users/${userID}/invite_team_member`,
    data,
  )
}

function updateTeamMember(userID, teamID, data) {
  return axios.patch(
    `${config.REACT_APP_BACKEND_URL}/teams/${teamID}/users/${userID}/update_team_member`,
    data,
  )
}

function inviteCoWorker(userID, data) {
  return axios.post(`${config.REACT_APP_BACKEND_URL}/users/${userID}/invite_coworker`, data)
}

function updateCoWorker(userID, data) {
  return axios.patch(`${config.REACT_APP_BACKEND_URL}/users/${userID}/update_coworker`, data)
}

function getTeamMembers(userID, teamID, query) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/teams/${teamID}/get_team_members${query}`,
  )
}

function getCoWorkers(userID, query) {
  return axios.get(`${config.REACT_APP_BACKEND_URL}/users/${userID}/get_coworkers${query}`)
}
function getPlatformAdmins(userID, query) {
  return axios.get(`${config.REACT_APP_BACKEND_URL}/users/${userID}/get_platform_admins${query}`)
}

function getTeamsForCoWorkerInvite(userID, search) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/get_teams_for_coworker_invite${search}`,
  )
}

function deleteTeamMember(userID, teamID, data) {
  return axios.post(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/teams/${teamID}/kick_user_of_team`,
    data,
  )
}

function deleteCoWorker(userID, data) {
  return axios.post(`${config.REACT_APP_BACKEND_URL}/users/${userID}/kick_coworker_of_team`, data)
}

function deletePlatformAdmin(userID, data) {
  return axios.post(`${config.REACT_APP_BACKEND_URL}/users/${userID}/delete_platform_admin`, data)
}

function toggleCanManageTeamMembers(userID, teamID, data) {
  return axios.post(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/teams/${teamID}/toggle_can_manage_team_members`,
    data,
  )
}

export default {
  getTeamsForUser,
  getTeamForUser,
  getTeamAccounts,
  getTeamMembers,
  getCoWorkers,
  getPlatformAdmins,
  getTeamRoles,
  getPlatformRoles,
  getPermissions,
  createTeam,
  leaveTeam,
  deleteTeam,
  changeTeamName,
  createTeamRole,
  createPlatformRole,
  updateTeamRole,
  updatePlatformRole,
  deleteTeamRole,
  deletePlatformRole,
  inviteTeamMember,
  updateTeamMember,
  deleteTeamMember,
  inviteCoWorker,
  updateCoWorker,
  deleteCoWorker,
  invitePlatformAdmin,
  deletePlatformAdmin,
  toggleCanManageTeamMembers,
  getTeamsForCoWorkerInvite,
}
