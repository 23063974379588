import ACTIONS from "../../constants/ACTIONS"

const initialState = {
  myTemplates: [],
  defaultTemplates: [],
  images: [],
}

export default function imagePersonalizationReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.GET_MY_TEMPLATES: {
      return {
        ...state,
        myTemplates: action.myTemplates,
      }
    }

    case ACTIONS.GET_DEFAULT_TEMPLATES: {
      return {
        ...state,
        defaultTemplates: action.defaultTemplates,
      }
    }

    case ACTIONS.GET_PERSONALIZED_IMAGE_DATA: {
      return {
        ...state,
        images: [...state.images, { id: action.imageId, data: action.imageData }],
      }
    }

    case ACTIONS.USER_LOGOUT: {
      return initialState
    }

    default:
      return { ...state }
  }
}
