import "./RadioButton.scss"

import PropTypes from "prop-types"
import React from "react"

const RadioButton = ({ checked, defaultChecked, disabled, name, onChange }) => {
  return (
    <div className="radio-btn-container">
      <label className="radio-btn">
        <input
          type="radio"
          name={name}
          checked={checked}
          defaultChecked={defaultChecked}
          onChange={onChange}
          disabled={disabled}
        />
        <span className="radio-btn-indicator" />
      </label>
    </div>
  )
}

RadioButton.propTypes = {
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
}

RadioButton.defaultProps = {
  checked: undefined,
  defaultChecked: undefined,
  disabled: false,
  name: "",
  onChange: () => {},
}

export default RadioButton
