import "./CiCDPage.scss"
import "react-circular-progressbar/dist/styles.css"

import React, { useEffect, useState } from "react"
import { CircularProgressbar } from "react-circular-progressbar"

const fromStartdifference = +new Date(`2024-08-01`) - +new Date(`2024-03-25`)
// const totlaDays = Math.floor(fromStartdifference / (1000 * 60 * 60 * 24))

const CiCDPage = () => {
  const calculateTimeLeft = () => {
    const fromNowdifference = +new Date(`2024-08-01`) - +new Date()

    let timeLeft = {}
    if (fromNowdifference > 0) {
      const days = Math.floor(fromNowdifference / (1000 * 60 * 60 * 24))
      const hours = Math.floor((fromNowdifference / (1000 * 60 * 60)) % 24)
      const minutes = Math.floor((fromNowdifference / 1000 / 60) % 60)
      const seconds = Math.floor((fromNowdifference / 1000) % 60)

      timeLeft = {
        days: {
          left: days,
          maxValue: Math.floor(fromStartdifference / (1000 * 60 * 60 * 24)),
          pertencage: Math.floor((fromNowdifference / fromStartdifference) * 100),
        },
        hours: {
          left: hours,
          maxValue: 24,
          pertencage: Math.floor((hours / 24) * 100),
        },
        minutes: {
          left: minutes,
          maxValue: 60,
          pertencage: Math.floor((minutes / 60) * 100),
        },
        seconds: {
          left: seconds,
          maxValue: 60,
          pertencage: Math.floor((seconds / 60) * 100),
        },
      }
    }

    return timeLeft
  }

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft())
    }, 1000)

    return () => clearTimeout(timer)
  })

  const timerComponents = []

  Object.keys(timeLeft).forEach(interval => {
    if (typeof timeLeft[interval] === "undefined") {
      return
    }

    timerComponents.push(
      <div key={interval} className="circular-timer-wrapper">
        <CircularProgressbar
          strokeWidth={10}
          className="circular-timer"
          value={`${timeLeft[interval].pertencage}`}
          text={`${timeLeft[interval].left}`}
        />

        <p>{interval}</p>
      </div>,
    )
  })

  return (
    <div className="countdown-page">
      <h1>Finish Strong! CI/CD Project Countdown</h1>

      <div className="countdown-timer">
        {timerComponents.length ? (
          timerComponents
        ) : (
          <span role="img" aria-label="Project Complete">
            Project Complete 🎉
          </span>
        )}
      </div>
    </div>
  )
}

export default CiCDPage
