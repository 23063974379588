import axios from "axios"

export const compressImage = (image, formData, url, fieldName) => {
  return new Promise(resolve => {
    if (image.type === "image/gif") {
      formData.append(fieldName, image)
      resolve(
        axios.post("https://api.cloudinary.com/v1_1/skylead/image/upload", formData, {
          headers: { "X-Requested-With": "XMLHttpRequest" },
          withCredentials: false,
        }),
      )
    } else {
      const fileName = image.name
      const reader = new FileReader()
      reader.readAsDataURL(image)
      reader.onload = event => {
        const img = new Image()
        img.src = event.target.result
        img.onload = () => {
          const maxWidth = 900 // Max width for the image
          const maxHeight = 900 // Max height for the image
          let ratio = 0 // Used for aspect ratio
          let { width } = img // Current image width
          let { height } = img // Current image height

          // Check if the current width is larger than the max
          if (width > maxWidth) {
            ratio = maxWidth / width // get ratio for scaling image
            height *= ratio // Reset height to match scaled image
            width *= ratio // Reset width to match scaled image
          }

          // Check if current height is larger than max
          if (height > maxHeight) {
            ratio = maxHeight / height // get ratio for scaling image
            width *= ratio // Reset width to match scaled image
            height *= ratio // Reset height to match scaled image
          }

          const elem = document.createElement("canvas")
          elem.width = width
          elem.height = height

          const ctx = elem.getContext("2d")
          // img.width and img.height will contain the original dimensions
          ctx.drawImage(img, 0, 0, width, height)
          ctx.canvas.toBlob(
            blob => {
              const file = new File([blob], fileName, {
                type: "image/jpeg",
                lastModified: Date.now(),
              })

              formData.append(fieldName, file)

              const metaData =
                fieldName === "file"
                  ? {
                      headers: { "X-Requested-With": "XMLHttpRequest" },
                      withCredentials: false,
                    }
                  : {}

              resolve(axios.post(url, formData, metaData))
            },
            "image/jpeg",
            1,
          )
        }
      }
    }
  })
}

const removeImageFromMessages = (item, imageId) => {
  const { messages, personalizedImageIds } = item.data

  if (Array.isArray(personalizedImageIds) && personalizedImageIds?.length) {
    personalizedImageIds.forEach((id, index) => {
      if (id === imageId && index < messages.length) {
        const message = messages[index]
        const parser = new DOMParser()
        const htmlDom = parser.parseFromString(message, "text/html")

        const imageTemplateDiv = htmlDom.querySelector(".image-template")

        if (imageTemplateDiv) {
          imageTemplateDiv.parentNode.removeChild(imageTemplateDiv)
        }

        messages[index] = htmlDom.body.innerHTML
      }
    })
  }
}

const removeImageFromData = (newItem, imageId) => {
  if (newItem.data.personalizedImageIds.length > 0) {
    newItem.data.personalizedImageIds = newItem.data.personalizedImageIds.map(id => {
      if (id === imageId) {
        return null
      }
      return id
    })
  }

  if (newItem.data.personalizedImageId === imageId) {
    newItem.data.personalizedImageId = null
  }

  if (newItem.data.personalizedImageData.length > 0) {
    newItem.data.personalizedImageData = newItem.data.personalizedImageData.map(data => {
      if (data && data.id === imageId) {
        return null
      }
      return data
    })
  }
}

export const deleteImageTemplateFromCampaign = (imageId, allElements, changeAllElements) => {
  if (allElements) {
    const deepCopyAllElements = JSON.parse(JSON.stringify(allElements))
    const mappedAllElements = deepCopyAllElements.map(item => {
      if (item.action === "message" || item.action === "inMail") {
        removeImageFromData(item, imageId)
      }
      if (item.action === "email") {
        removeImageFromMessages(item, imageId)
        removeImageFromData(item, imageId)
      }
      return item
    })

    changeAllElements(mappedAllElements)
  }
}
