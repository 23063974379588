import "./Loader.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import SvgIcon from "../SvgIcon"

const Loader = ({ large, small, blackSmallLoader, margin, fullWidth }) => {
  const smallLoaderClassNames = classNames("loader-icon-small", {
    fullWidth,
    black: blackSmallLoader,
  })

  const isLarge = large || fullWidth

  return (
    <div className="loader-container">
      <div className="loader-wrapper" style={{ margin }}>
        {isLarge && <SvgIcon spanClassName="loader-icon-large" icon="rotate-clockwise-2-80-80" />}
        {small && <SvgIcon spanClassName={smallLoaderClassNames} icon="loading-20" />}
      </div>
    </div>
  )
}

Loader.propTypes = {
  large: PropTypes.bool,
  small: PropTypes.bool,
  blackSmallLoader: PropTypes.bool,
}

Loader.defaultProps = {
  large: false,
  small: false,
  blackSmallLoader: false,
}

export default Loader
