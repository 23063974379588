import PropTypes from "prop-types"
import { useEffect } from "react"
import { withRouter } from "react-router-dom"

const ScrollToTop = ({ location, children }) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  return children
}

ScrollToTop.propTypes = {
  location: PropTypes.instanceOf(Object).isRequired,
  children: PropTypes.instanceOf(Object).isRequired,
}

ScrollToTop.defaultProps = {}

export default withRouter(ScrollToTop)
