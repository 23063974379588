/* eslint-disable no-use-before-define */
import "react-dates/initialize"
import "react-dates/lib/css/_datepicker.css"
import "react-quill/dist/quill.snow.css"
import "./InputGroup.scss"

import { CardElement } from "@stripe/react-stripe-js"
import classNames from "classnames"
import { Picker } from "emoji-mart"
import { debounce } from "lodash"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import TextareaAutosize from "react-autosize-textarea/lib"
import { Dropdown, Form, InputGroup as BSInputGroup } from "react-bootstrap"
import { DateRangePicker, SingleDatePicker } from "react-dates"
import ReactQuill from "react-quill"
import { useDispatch, useSelector } from "react-redux"
import TimezonePicker, { timezones } from "react-timezone"
import { toast } from "react-toastify"

import { clearFormError, closeModal, showModal, updateFormField } from "../../../redux/actions"
import { useOnClickOutside } from "../../../utils/hook"
import CustomMenu from "../../atoms/CustomMenu"
import CustomToggle from "../../atoms/CustomToggle"
import InputButton from "../../atoms/InputButton"
import { ModalVariant } from "../../atoms/Modal"
import SvgIcon from "../../atoms/SvgIcon"
import TimePicker from "../../atoms/TimePicker"
import { FooterVariant } from "../../molecules/Footer"

const InputGroup = ({
  accept,
  after,
  append,
  ariaLabel,
  autoComplete,
  autoFocus,
  before,
  bordered,
  children,
  customError,
  customValue,
  description,
  disabled,
  expandable,
  extraInput,
  field,
  gray,
  hideMaxLength,
  iconClassName,
  iconName,
  inputClassName,
  inputGroupClassName,
  inputLabel,
  inputProps,
  isConnectMessage,
  label,
  labelColor,
  margin,
  maxLength,
  multiplePasswordType,
  noFormError,
  noRedux,
  onChange,
  onEnter,
  onIconClick,
  onKeyUp,
  outerErrorMessage,
  passwordType,
  placeholder,
  prepend,
  preview,
  required,
  rightLabel,
  setCurrentEvent,
  setMultiplePasswordType,
  setPasswordType,
  siblingComponent,
  style,
  transparent,
  type,
  useDebounce,
  useEnterAsDefault,
  variant,
  tooltipText,
  tooltipLeft,
  tooltipTop,
  caret,
  iconWrapperClassName,
  readOnly,
  canCopyText,
  message,
  numberInputLabel,
  actionBar,
  imageActionBar,
  signatureActionBar,
  previewActionBar,
  showExpandActionBar,
  onClickExpandActionBar,
  customToolbar,
  isDropdownMenu,
  dropdownSelectedItem,
  extend,
  tabIndex,
  addEmojiActionBar,
  savedRepliesActionBar,
}) => {
  const dispatch = useDispatch()
  const { formData, formError } = useSelector(state => ({
    formData: state.forms.formData,
    formError: state.forms.formErrors,
  }))

  const inputValue = useMemo(() => {
    if (type === "file") {
      return ""
    }

    if (type === "timezone") {
      if (typeof formData[field] !== "undefined") {
        return formData[field].name || ""
      }
      return ""
    }

    if (type === "html") {
      if (typeof formData[field] !== "undefined") {
        return formData[field] || ""
      }
      if (customValue !== null) {
        return customValue
      }
      return ""
    }

    if (customValue !== null) {
      if (!customValue && customValue !== 0) {
        return ""
      }
      return customValue
    }
    if (typeof formData[field] !== "undefined") {
      if (!formData[field] && formData[field] !== 0) {
        return ""
      }

      return formData[field]
    }
    return ""
  }, [field, type, formData[field], customValue])

  const showActionBar = useMemo(() => {
    return (
      imageActionBar ||
      signatureActionBar ||
      previewActionBar ||
      showExpandActionBar ||
      addEmojiActionBar ||
      savedRepliesActionBar
    )
  }, [
    imageActionBar,
    signatureActionBar,
    previewActionBar,
    showExpandActionBar,
    addEmojiActionBar,
    savedRepliesActionBar,
  ])

  const [characterCount, setCharacterCount] = useState(0)
  const [tempValue, setTempValue] = useState(inputValue)

  const tagReplacementLength = 10

  useEffect(() => {
    if (inputValue !== tempValue) {
      setTempValue(inputValue)
    }
  }, [inputValue])

  useEffect(() => {
    if (isConnectMessage) {
      const tags = tempValue.match(/{{(.*?)}}/g) || []
      const numberOfTags = tags.length
      let sumOfTagsLengths = 0
      tags.forEach(tag => {
        sumOfTagsLengths += tag.length
      })
      /** Calculations below are necessary in the case of connect message, as each tag is counted as
       *  10 characters regardless of its actual length.
       */
      const numberOfInputCharacters =
        tempValue.length - sumOfTagsLengths + numberOfTags * tagReplacementLength
      setCharacterCount(numberOfInputCharacters)
      dispatch(updateFormField("connectMessageCustomCharacterCount", numberOfInputCharacters))
    }
  }, [tempValue])

  const handleChange = event => {
    if (formError[field] && event && event.target && event.target.id && !noRedux) {
      dispatch(clearFormError(event.target.id))
    }

    if (type === "timezone") {
      const timezoneData = timezones.find(timezone => timezone.name === event)
      dispatch(updateFormField(field, timezoneData))
    } else if (type === "timepicker") {
      dispatch(updateFormField(field, event))
    } else if (type === "datepicker") {
      dispatch(updateFormField(field, event))
    } else if (type === "html") {
      if (!noRedux) {
        dispatch(updateFormField(field, event))
      }
    } else if (type !== "card") {
      const { id, value, files } = event.target || {}
      let data = value
      if (type === "file") {
        const [file] = files
        data = file
      }

      if (!noRedux) {
        dispatch(updateFormField(id, data))
      }
    }

    if (onChange) {
      onChange(event)
    }
  }

  const dataHandler = useCallback(debounce(handleChange, useDebounce ? 500 : 0), [handleChange])

  useEffect(() => {
    if (["html", "timepicker", "datepicker", "timezone"].includes(type)) {
      dataHandler(tempValue)
    } else {
      const dataObj = { id: field || type, value: tempValue }
      dataHandler({ target: dataObj, currentTarget: dataObj, persist: () => {} })
    }
    return () => {
      dataHandler.cancel()
    }
  }, [tempValue])

  const handleChangeWrapper = e => {
    const newValue = ["html", "timepicker", "datepicker", "timezone"].includes(type)
      ? e
      : e?.target?.value || e?.currentTarget?.value || ""

    setTempValue(newValue)
    if (setCurrentEvent) {
      e.persist()
      setCurrentEvent(e)
    }
  }

  const error = (!noFormError && formError[field]) || customError || ""

  const inputGroupClass = classNames("input-group-wrapper", {
    "input-transparent": transparent,
    "input-gray": gray,
    "input-error": error,
    "input-bordered": bordered,
    "icon-input": !!iconName,
    "number-input": type === "number",
    "input-extend": extend,
    [variant]: true,
    [inputGroupClassName]: inputGroupClassName,
    "code-input": type === "code",
  })
  const iconClassNames = classNames(iconClassName, "input-icon")
  const labelClassName = classNames("small", {
    "form-label-small": transparent,
    "label-disabled": disabled,
  })

  const lengthAndMessageClassName = classNames("form-input-maxlength", "small", {
    "input-length-exceeded": isConnectMessage
      ? characterCount >= Number(maxLength)
      : tempValue?.length === Number(maxLength),
    "maxlength-disabled": disabled,
    "maxlength-error": error,
    "maxlength-message": message,
  })

  const messageClassName = classNames("message-input", "small", {
    "message-input-disabled": disabled,
    "message-input-error": error,
  })

  const passwordIconClassName = classNames("password-icon", {
    "password-icon-readonly": readOnly,
    "password-icon-disabled": disabled,
  })

  const copyIconClassName = classNames("copy-icon", {
    "copy-icon-readonly": readOnly,
    "copy-icon-disabled": disabled,
  })

  const arrowDownIconClassName = classNames("chevron-down-icon", {
    "chevron-down-icon-readonly": readOnly,
    "chevron-down-icon-disabled": disabled,
  })

  const placeHolderTextClassName = classNames("placeholder-text", {
    "placeholder-text-disabled": disabled,
  })

  const handleKeyUp = event => {
    if (
      formError[field] &&
      event &&
      event.target &&
      event.target.id &&
      event.key !== "Enter" &&
      Object.keys(formError).includes([
        "randomAdditionalDelay",
        "dailyEmailLimit",
        "isGreaterThan15Minutes",
      ])
    ) {
      dispatch(clearFormError(event.target.id))
    }

    if (onKeyUp) {
      onKeyUp(event)
    }
  }

  /** Array of symbols that are not allowed as input when the input type is "number". */
  const ignoreSymbols = ["-", "+", "e", "E"]

  const handleKeyDown = event => {
    if (type === "number" && ignoreSymbols.includes(event.key)) {
      event.preventDefault()
    }

    if (event.key === "Enter") {
      if (!useEnterAsDefault) {
        event.preventDefault()
        if (onEnter) {
          onEnter(event)
        }
      }
      /** This return is necessary to continue original event propagation */
      // eslint-disable-next-line no-useless-return
      return
    }
  }

  const handlePaste = event => {
    const pasteContent = (event.clipboardData || window.clipboardData).getData("text")

    if (type === "number" && ignoreSymbols.some(symbol => pasteContent.includes(symbol))) {
      event.preventDefault()
    }
  }

  const getInputType = () => {
    if (type === "card") {
      return CardElement
    }
    if (type === "select") {
      return "select"
    }
    if (type === "timezone") {
      return TimezonePicker
    }
    if (type === "timepicker") {
      return TimePicker
    }
    if (type === "code") {
      return TextareaAutosize
    }
    if (type === "textarea") {
      return TextareaAutosize
    }

    if (type === "html") {
      return ReactQuill
    }
    if (type === "datepicker") {
      return SingleDatePicker
    }
    if (type === "daterangepicker") {
      return DateRangePicker
    }
  }

  const handleNumberChange = value => {
    if (inputProps) {
      const { min, max } = inputProps

      if ((typeof max === "number" && value > max) || (typeof min === "number" && value < min)) {
        return
      }
    }

    setTempValue(value)

    if (onChange) {
      onChange({ target: { value } })
    }
  }

  const handleIncrement = () => {
    handleNumberChange((parseInt(tempValue, 10) || 0) + 1)
  }

  const handleDecrement = () => {
    handleNumberChange((parseInt(tempValue, 10) || 0) - 1)
  }

  const emojiRef = useRef(null)
  const [showEmojiPicker, toggleEmojiPicker] = useState(false)
  useOnClickOutside(emojiRef, () => toggleEmojiPicker(false))

  const addEmojiHandler = emoji => {
    addEmojiActionBar(emoji)
    toggleEmojiPicker(false)
  }

  const fontClassName = "large"

  const formControl = (
    <Form.Control
      aria-label={placeholder || label || ariaLabel}
      key={field}
      as={getInputType()}
      className={classNames(inputClassName, fontClassName)}
      type={type}
      name={field}
      placeholder={placeholder}
      value={tempValue}
      onChange={handleChangeWrapper}
      onKeyDown={handleKeyDown}
      onKeyUp={handleKeyUp}
      required={required}
      maxLength={isConnectMessage ? undefined : maxLength}
      autoComplete={autoComplete}
      accept={accept}
      disabled={disabled}
      autoFocus={autoFocus}
      onPaste={handlePaste}
      readOnly={readOnly}
      {...inputProps}
    >
      {children}
    </Form.Control>
  )

  const dropdownMenu = (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle} disabled={disabled} readOnly={readOnly}>
        <span className={classNames("dropdown-selected-item", fontClassName)}>
          {dropdownSelectedItem || <span className={placeHolderTextClassName}>{placeholder}</span>}
        </span>
        <SvgIcon icon="chevron-down-20" className={arrowDownIconClassName} />
      </Dropdown.Toggle>
      <Dropdown.Menu as={CustomMenu} className="title-dropdown-menu" small noSearch>
        {children}
      </Dropdown.Menu>
    </Dropdown>
  )

  const renderData = hide => (
    <>
      {before}
      <div className={inputGroupClass} style={{ ...style, margin }} key={field}>
        <Form.Group controlId={field}>
          {(label || rightLabel) && (
            <div className="label-padding">
              {label && (
                <span className={labelClassName} style={{ color: labelColor }}>
                  {label}
                </span>
              )}
              {rightLabel && rightLabel}
            </div>
          )}
          <div className="position-relative">
            <BSInputGroup>
              {prepend && (
                <BSInputGroup.Prepend>
                  <BSInputGroup.Text id="basic-addon1">{prepend}</BSInputGroup.Text>
                </BSInputGroup.Prepend>
              )}

              {actionBar ? (
                <div className="action-bar-form">
                  {customToolbar && customToolbar}
                  {formControl}
                  {showActionBar && (
                    <div className="action-bar-input">
                      <div className="action-bar-input-start-icons">
                        {imageActionBar && imageActionBar}
                        {signatureActionBar && signatureActionBar}
                        {addEmojiActionBar && (
                          <div className="emoji-picker-wrapper">
                            {showEmojiPicker ? (
                              <div ref={emojiRef}>
                                <Picker onSelect={addEmojiHandler} />
                              </div>
                            ) : null}
                            <InputButton
                              icon="mood-smile-20"
                              onClick={() => {
                                toggleEmojiPicker(!showEmojiPicker)
                              }}
                            />
                          </div>
                        )}
                        {savedRepliesActionBar && savedRepliesActionBar}
                      </div>
                      <div className="action-bar-input-end-icons">
                        {previewActionBar && previewActionBar}
                        {showExpandActionBar && (
                          <InputButton
                            icon={
                              customToolbar?.props?.expand
                                ? "arrows-minimize-20"
                                : "arrows-maximize-20"
                            }
                            onClick={onClickExpandActionBar}
                            tooltipObj={{
                              text: customToolbar?.props?.expand ? "Minimize" : "Maximize",
                              topPos: 45,
                              leftPos: 27,
                            }}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </div>
              ) : isDropdownMenu ? (
                dropdownMenu
              ) : (
                formControl
              )}

              {append && (
                <BSInputGroup.Append>
                  <BSInputGroup.Text id="inputGroupPrepend">{append}</BSInputGroup.Text>
                </BSInputGroup.Append>
              )}
              {siblingComponent}
              {inputLabel && (
                <label className="custom-file-label" htmlFor={field}>
                  {inputLabel}
                </label>
              )}

              {preview && preview}
              {extraInput && extraInput}

              {numberInputLabel && (
                <div className="large number-input-label">{numberInputLabel}</div>
              )}
              {type === "number" && !disabled && !readOnly && (
                <div className="number-input-stepper">
                  <SvgIcon icon="vertical-divider" className="hr-divider-icon" />
                  <InputButton tabIndex={tabIndex} icon="minus-20" onClick={handleDecrement} />
                  <InputButton tabIndex={tabIndex} icon="plus-20" onClick={handleIncrement} />
                </div>
              )}

              {!!multiplePasswordType && Object.keys(multiplePasswordType).includes(field) && (
                <div className={passwordIconClassName}>
                  <SvgIcon
                    icon={multiplePasswordType[field] !== "password" ? "eye-20" : "eye-off-20"}
                    onClick={() => {
                      setMultiplePasswordType({
                        ...multiplePasswordType,
                        [field]: multiplePasswordType[field] === "password" ? "text" : "password",
                      })
                    }}
                  />
                </div>
              )}

              {passwordType === "password" && (
                <div className={passwordIconClassName}>
                  <SvgIcon
                    icon="eye-off-20"
                    onClick={() => {
                      setPasswordType("text")
                    }}
                  />
                </div>
              )}

              {passwordType === "text" && (
                <div className={passwordIconClassName}>
                  <SvgIcon
                    icon="eye-20"
                    onClick={() => {
                      setPasswordType("password")
                    }}
                  />
                </div>
              )}

              {canCopyText && (
                <div className={copyIconClassName}>
                  <SvgIcon
                    icon="copy-20"
                    onClick={() => {
                      navigator.clipboard.writeText(tempValue)
                      toast.success("Copied to clipboard")
                    }}
                  />
                </div>
              )}

              {/* TODO MJ@Any: Change componenets to use this insted of seperate expand */}
              {expandable && (
                <SvgIcon
                  icon="input-expand"
                  onClick={() => {
                    onExpand(hide)
                  }}
                  className="expand-icon-email"
                />
              )}
            </BSInputGroup>
            {/* {error ? (
              <span className={errorIconClassNames}>!</span>
            ) : (
              !!iconName && (
                <span onClick={onIconClick} className={iconWrapperClassName}>
                  <SvgIcon
                    style={{ zIndex: 1 }}
                    icon={iconName}
                    className={iconClassNames}
                    tooltipText={tooltipText}
                    tooltipLeft={tooltipLeft}
                    tooltipTop={tooltipTop}
                    caretBottom={caret}
                  />
                </span>
              )
            )} */}
            {!!iconName && (
              <span onClick={onIconClick} className={iconWrapperClassName}>
                <SvgIcon
                  style={{ zIndex: 1 }}
                  icon={iconName}
                  className={iconClassNames}
                  tooltipText={tooltipText}
                  tooltipLeft={tooltipLeft}
                  tooltipTop={tooltipTop}
                  caret={caret}
                />
              </span>
            )}
          </div>
          {outerErrorMessage && <div className="outer-error-container">{outerErrorMessage}</div>}
          {description && <span className="form-description">{description}</span>}
          {(message || (!hideMaxLength && maxLength)) && (
            <div className={lengthAndMessageClassName}>
              {message && <div className={messageClassName}>{message}</div>}
              {!hideMaxLength && maxLength && (
                <div>
                  {isConnectMessage ? characterCount : tempValue.length}/{maxLength}
                </div>
              )}
            </div>
          )}
          {/* {message && <div className={messageClassName}>{message}</div>} */}
          {/* {error && (
            <span className="error-message">
              <SvgIconRound icon="error-icon" className="error-message-icon" />
              {error}
            </span>
          )} */}
        </Form.Group>
      </div>
      {after}
    </>
  )

  const onExpand = hide => {
    if (hide) {
      dispatch(closeModal())
    } else {
      dispatch(
        showModal(ModalVariant.LARGE, "Email", renderData(true), FooterVariant.ACKNOWLEDGMENT, {
          label: "Cancel",
        }),
      )
    }
  }

  return renderData()
}

InputGroup.propTypes = {
  accept: PropTypes.string,
  append: PropTypes.string,
  ariaLabel: PropTypes.string,
  autoComplete: PropTypes.oneOf(["on", "off"]),
  autoFocus: PropTypes.bool,
  bordered: PropTypes.bool,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  field: PropTypes.string,
  gray: PropTypes.bool,
  iconName: PropTypes.string,
  inputGroupClassName: PropTypes.string,
  inputLabel: PropTypes.string,
  inputProps: PropTypes.instanceOf(Object),
  isConnectMessage: PropTypes.bool,
  label: PropTypes.string,
  labelColor: PropTypes.string,
  margin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  noFormError: PropTypes.bool,
  noRedux: PropTypes.bool,
  onChange: PropTypes.func,
  onKeyUp: PropTypes.func,
  transparent: PropTypes.bool,
  placeholder: PropTypes.string,
  prepend: PropTypes.oneOf([PropTypes.instanceOf(Object), PropTypes.string]),
  setCurrentEvent: PropTypes.func,
  siblingComponent: PropTypes.element,
  style: PropTypes.instanceOf(Object),
  type: PropTypes.oneOf([
    "text",
    "email",
    "number",
    "file",
    "card",
    "select",
    "timezone",
    "timepicker",
    "textarea",
    "datepicker",
    "password",
    "html",
  ]),
  useDebounce: PropTypes.bool,
  variant: PropTypes.oneOf(["default", "backgrounded"]),
  customValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.number]),
  iconWrapperClassName: PropTypes.string,
  readOnly: PropTypes.bool,
  canCopyText: PropTypes.bool,
  message: PropTypes.string,
  numberInputLabel: PropTypes.string,
  actionBar: PropTypes.element,
  showImageActionBar: PropTypes.bool,
  showSignatureActionBar: PropTypes.bool,
  showPreviewActionBar: PropTypes.bool,
  showExpandActionBar: PropTypes.bool,
  customToolbar: PropTypes.element,
  onClickSignatureActionBar: PropTypes.func,
  onClickPreviewActionBar: PropTypes.func,
  onClickExpandActionBar: PropTypes.func,
  isDropdownMenu: PropTypes.bool,
  dropdownSelectedItem: PropTypes.string,
  extend: PropTypes.bool,
}

InputGroup.defaultProps = {
  accept: "",
  after: null,
  append: "",
  ariaLabel: "",
  autoComplete: "off",
  autoFocus: false,
  before: null,
  bordered: false,
  children: null,
  customValue: null,
  disabled: false,
  field: undefined,
  gray: false,
  iconName: "",
  inputGroupClassName: "",
  inputLabel: undefined,
  inputProps: {},
  isConnectMessage: false,
  label: "",
  labelColor: undefined,
  margin: "",
  noFormError: false,
  noRedux: false,
  onChange: undefined,
  onKeyUp: undefined,
  placeholder: "",
  prepend: null,
  setCurrentEvent: undefined,
  siblingComponent: undefined,
  style: {},
  transparent: false,
  type: "text",
  useDebounce: false,
  variant: "default",
  iconWrapperClassName: "",
  readOnly: false,
  canCopyText: false,
  message: "",
  numberInputLabel: "",
  actionBar: undefined,
  showImageActionBar: false,
  showSignatureActionBar: false,
  showPreviewActionBar: false,
  showExpandActionBar: false,
  customToolbar: undefined,
  onClickSignatureActionBar: undefined,
  onClickPreviewActionBar: undefined,
  onClickExpandActionBar: undefined,
  isDropdownMenu: false,
  dropdownSelectedItem: "",
  extend: false,
}

export default React.memo(InputGroup)
